import axios from "axios";
import { environment } from "../environments/environment";
import { detect } from 'detect-browser';
import { isTablet, isBrowser, isMobile } from 'react-device-detect';

export default class ParcoursService {

    static baseUrl: string = environment.url;

    static add_important_post_data(body: any, product: string) {
        const device = detect();

        body.append('operateur_id', 'ca17941f-87f0-4122-a45a-e3fa4626eb80');
        body.append('provenance_web', 'front');
        body.append('secu_id', product === "DevisSanteClient" ? localStorage.getItem('secu_id_sante') : localStorage.getItem('secu_id_emprunteur'));
        body.append('private_id', product === "DevisSanteClient" ? localStorage.getItem('private_id_sante') : localStorage.getItem('private_id_emprunteur'));
        body.append('browser', device?.name);
        body.append('browser_version', device?.version);
        body.append('os', device?.os);
        body.append('os_version', "unknown");
        body.append('is_mobile', isMobile);
        body.append('is_tablet', isTablet);
        body.append('is_desktop', isBrowser);
        body.append('url_ad', product === "DevisSanteClient" ? localStorage.getItem('url_ad_sante') : localStorage.getItem('url_ad_emprunteur'));
        body.append('source', 'julia-mutuelle.fr');
    };

    static async api_init_step(step: string, product: string) {
        var data: any;
        const url = `${this.baseUrl}/stepManager/initStep`;

        const body = new FormData();

        body.append("current_step", step)
        this.add_important_post_data(body, product);

        await axios.post(url, body).then((response) => {
            data = response.data;
        }).catch((error) => {
            throw error
        });
        return data;
    };

    static async api_check_step_manager(product: string) {
        var data: any;
        const url = `${this.baseUrl}/stepManager/checkStepManager`;
        const body = new FormData();

        this.add_important_post_data(body, product);
        body.append('parcours_type', product);
        body.append('from', 'front');

        await axios.post(url, body).then((response) => {
            data = response.data;
        }).catch((error) => {
            throw error
        });
        return data;
    };

    static async api_create_step_manager(product: string) {
        var data: any;
        const url = `${this.baseUrl}/stepManager/startStepManager`;
        const body = new FormData();

        body.append('parcours_type', product);
        body.append('from', 'front');
        body.append('operateur_id', 'ca17941f-87f0-4122-a45a-e3fa4626eb80');

        await axios.post(url, body).then((response) => {
            data = response.data;
        }).catch((error) => {
            throw error
        });
        return data;
    };

    static async api_go_to_last_step(product: string) {
        var data: any;
        const url = `${this.baseUrl}/stepManager/apiGoToLastStep`;
        const body = new FormData();

        this.add_important_post_data(body, product);
        body.append('current_step', '');

        await axios.post(url, body).then((response) => {
            data = response.data;
        }).catch((error) => {
            throw error
        });
        return data;
    };

    static async api_reset(product: string) {
        var data: any;
        const url = `${this.baseUrl}/stepManager/apiReset`;
        const body = new FormData();

        this.add_important_post_data(body, product);
        body.append('current_step', '');

        await axios.post(url, body).then((response) => {
            data = response.data;
        }).catch((error) => {
            throw error
        });
        return data;
    };

    static async api_get_next_step(step: string, product: string, data?: any, log?: any) {
        var returnData: any;
        const url = `${this.baseUrl}/stepManager/getNextStep`;
        const body = new FormData();

        for (const key in data) {
            if (data.hasOwnProperty(key) && data[key] !== undefined && data[key] !== null) {
                body.append(key, JSON.stringify(data[key]));
            }
        }
        this.add_important_post_data(body, product);
        body.append('provenance_signature', 'client');
        body.append('current_step', step);
        body.append('log', log);

        await axios.post(url, body).then((response) => {
            returnData = response.data;
        }).catch((error) => {
            throw error
        });
        return returnData;
    };

    static async api_go_to_step(step: string, product: string, parcoursType?: string) {
        var data: any;
        const url = `${this.baseUrl}/stepManager/apiGoToStep`;
        const body = new FormData();

        this.add_important_post_data(body, product);
        body.append('current_step', step);
        if (parcoursType && parcoursType !== '') {
            body.append('parcours_type', parcoursType);
        }

        await axios.post(url, body).then((response) => {
            data = response.data;
        }).catch((error) => {
            throw error
        });
        return data;
    };

    static async api_go_to_last_finalize(product: string, parcoursType?: string) {
        var data: any;
        const url = `${this.baseUrl}/stepManager/apiGoToFirstFinalize`;
        const body = new FormData();

        this.add_important_post_data(body, product);
        body.append('current_step', '');
        if (parcoursType && parcoursType !== '') {
            body.append('parcours_type', parcoursType);
        }

        await axios.post(url, body).then((response) => {
            data = response.data;
        }).catch((error) => {
            throw error
        });
        return data;
    };

    static async api_get_tarif(step: string, product: string, data?: any) {
        var returnData: any;
        const url = `${this.baseUrl}/stepManager/apiGetTarif`;
        const body = new FormData();

        for (const key in data) {
            if (data.hasOwnProperty(key) && data[key] !== undefined && data[key] !== null) {
                body.append(key, JSON.stringify(data[key]));
            }
        }
        this.add_important_post_data(body, product);
        body.append('current_step', step);

        await axios.post(url, body).then((response) => {
            returnData = response.data;
        }).catch((error) => {
            throw error
        });
        return returnData;
    };

    static async api_get_panier(step: string, product: string) {
        var data: any;
        const url = `${this.baseUrl}/stepManager/apiGetPanier`;
        const body = new FormData();

        this.add_important_post_data(body, product);
        body.append('current_step', step);

        await axios.post(url, body).then((response) => {
            data = response.data;
        }).catch((error) => {
            throw error
        });
        return data;
    };

    static async api_change_status_devis(idDevis: string, newStatus: string, product: string) {
        var data: any;
        const url = `${this.baseUrl}/stepManager/changeStatusDevis`;
        const body = new FormData();

        this.add_important_post_data(body, product);
        body.append('id_devis', idDevis);
        body.append('new_status', newStatus);
        body.append('current_step', 'panier');

        await axios.post(url, body).then((response) => {
            data = response.data;
        }).catch((error) => {
            throw error
        });
        return data;
    };

    static async save_data_web(step: string, product: string, data?: any) {
        var returnData: any;
        const url = `${this.baseUrl}/stepManager/saveLastDataWeb`;
        const body = new FormData();

        for (const key in data) {
            if (data.hasOwnProperty(key) && data[key] !== undefined && data[key] !== null) {
                body.append(key, JSON.stringify(data[key]));
            }
        }
        this.add_important_post_data(body, product);
        body.append('current_step', step);

        await axios.post(url, body).then((response) => {
            returnData = response.data;
        }).catch((error) => {
            throw error
        });
        return returnData;
    };

    static async api_get_save_frais(data: any) {
        var returnData: any;
        const url = `${this.baseUrl}/stepManager/apiSaveGetFrais/`;

        await axios.post(url, data).then((response) => {
            returnData = response.data;
        }).catch((error) => {
            throw error
        });
        return returnData;
    };

    static async api_get_tarif_procento(data: any) {
        var returnData: any;
        const url = `${this.baseUrl}/stepManager/getTarifProcento/`;

        await axios.post(url, data).then((response) => {
            returnData = response.data;
        }).catch((error) => {
            throw error
        });
        return returnData;
    };

    static async api_get_devis_signature_infos(devisId: string, memberId: string) {
        var returnData: any;
        const url = `${this.baseUrl}/stepManager/apiGetDevisSignatureInfos/`;

        const body = new FormData();
        body.append('did', devisId);
        body.append('mid', memberId);

        await axios.post(url, body).then((response) => {
            returnData = response.data;
        }).catch(() => { });
        return returnData;
    };

    static async api_erreur_contrat() {
        var returnData: any;
        const url = `${this.baseUrl}/stepManager/erreurContrat`;

        const body = new FormData();
        this.add_important_post_data(body, "DevisSanteClient");
        body.append('current_step', 'end');

        await axios.post(url, body).then((response) => {
            returnData = response.data;
        }).catch(() => { });
        return returnData;
    };

    static async api_meb_contrat(lstId?: string) {
        var returnData: any;

        const url = `${this.baseUrl}/stepManager/endContrat`;
        const body = new FormData();
        this.add_important_post_data(body, "DevisSanteClient");
        if (lstId) { body.append('lst_devis', lstId) };
        body.append('current_step', 'end');
        await axios.post(url, body).then((response) => {
            returnData = response.data;
        }).catch(() => { });
        return returnData;
    };
};


export class AdminService {

    static baseUrl: string = environment.url;

    static async api_send_demand_rappel(data: any) {
        var returnData: any;
        const url = `${this.baseUrl}/admin_api/api_send_demand_rappel`;
        const body = new FormData();
        body.append('operateur_id', 'ca17941f-87f0-4122-a45a-e3fa4626eb80');

        for (const key in data) {
            if (data.hasOwnProperty(key) && data[key] !== undefined && data[key] !== null) {
                body.append(key, JSON.stringify(data[key]));
            }
        }

        await axios.post(url, body).then((response) => {
            returnData = response.data;
        }).catch((error) => {
            throw error
        });
        return returnData;
    };

    static async api_add_rdv(data: any, origine?: string, categorie?: string) {
        var returnData: any;
        const url = `${this.baseUrl}/admin_api/add_rdv`;
        const body = new FormData();

        body.append('operateur_id', 'ca17941f-87f0-4122-a45a-e3fa4626eb80');
        if (origine || categorie) {
            body.append('origine_lead', origine as string);
            body.append('categorie', categorie as string);
        }

        for (const key in data) {
            if (data.hasOwnProperty(key) && data[key] !== undefined && data[key] !== null) {
                body.append(key, JSON.stringify(data[key]));
            }
        }

        await axios.post(url, body).then((response) => {
            returnData = response.data;
        }).catch((error) => {
            throw error
        });
        return returnData;
    };

    static async api_get_devis_simple(data: any) {
        var returnData: any;

        const url = `${this.baseUrl}/admin_api/api_get_tarif_simple`;
        const body = new FormData();
        body.append('operateur_id', 'ca17941f-87f0-4122-a45a-e3fa4626eb80');

        for (const key in data) {
            if (data.hasOwnProperty(key) && data[key] !== undefined && data[key] !== null) {
                body.append(key, JSON.stringify(data[key]));
            }
        }

        await axios.post(url, body).then((response) => {
            returnData = response.data;
        }).catch((error) => {
            throw error
        });
        return returnData;
    };

    static async api_get_economie(data: any) {
        var returnData: any;

        const url = `${this.baseUrl}/admin_api/api_get_economie`;
        const body = new FormData();
        body.append('operateur_id', 'ca17941f-87f0-4122-a45a-e3fa4626eb80');

        for (const key in data) {
            if (data.hasOwnProperty(key) && data[key] !== undefined && data[key] !== null) {
                body.append(key, JSON.stringify(data[key]));
            }
        }

        await axios.post(url, body).then((response) => {
            returnData = response.data;
        }).catch((error) => {
            throw error
        });
        return returnData;
    };

    static async api_get_home_page(homepageUrl: string) {
        var returnData: any;

        const url = `${this.baseUrl}/pvm-api/api_get_home_page`;
        const body = new FormData();
        body.append('url', homepageUrl);

        await axios.post(url, body).then((response) => {
            returnData = response.data;
        }).catch((error) => {
            throw error
        });
        return returnData;
    };

    
    static async api_check_member_credentials(data: any) {
        var returnData: any;
        const url = `${this.baseUrl}/documents/api_check_member_credentials`;
        const body = new FormData();

        for (const key in data) {
            if (data.hasOwnProperty(key) && data[key] !== undefined && data[key] !== null) {
                body.append(key, JSON.stringify(data[key]));
            }
        }

        await axios.post(url, body).then((response) => {
            returnData = response.data;
        }).catch((error) => {
            throw error
        });
        return returnData;
    };

    
    static async api_save_documents(data: any) {
        var returnData: any;        
        const url = `${this.baseUrl}/documents/save_documents_client/`;
        const body = new FormData();
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            body.append(key, JSON.stringify(data[key]));
          }
        }

        await axios.post(url, body).then((response) => {
            returnData = response.data;
        }).catch((error) => {
            throw error
        });
        return returnData;
    };


    static async api_download_doc(doc_id:number,operateur_id:string) {
        const url = `${this.baseUrl}/documents/download_file_client/`;
        const body = new FormData();
        var returnData: any;       
        body.append('document_id', doc_id.toString());
        body.append('operateur_id', operateur_id);
        
        await axios.post(url, body).then((response) => {
            returnData = response.data;
        }).catch((error) => {
            throw error
        });
        return returnData;
    }

    static async api_delete_doc(doc_id:number,operateur_id:string) {
        const url = `${this.baseUrl}/documents/delete_document_client/`;
        const body = new FormData();
        var returnData: any;       
        body.append('doc_id', doc_id.toString());
        body.append('operateur_id', operateur_id);
        
        await axios.post(url, body).then((response) => {
            returnData = response.data;
        }).catch((error) => {
            throw error
        });
        return returnData;
    }
    
};