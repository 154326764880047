import React, { useState, useEffect, forwardRef } from 'react';
import { Calculator, ChevronRight, Euro } from 'lucide-react';
import { InsuranceCostChart } from '../../components/InsuranceCostChart/InsuranceCostChart';
import { AdminService } from '../../services/services';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Stack } from '@mui/material';
import { IoWarning } from 'react-icons/io5';
import Loading from '../../components/Loading/Loading';
import { TransitionProps } from '@mui/material/transitions';
import { CustomContainer } from '../../components/CustomContainer/CustomContainer';
import './SimulateurEmprunteur.css';


const phoneNumberRegex = new RegExp(`^(0|(00|\\+)33)[123456789][0-9]{8}$`);
const emailRegEx = new RegExp('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}');

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="down" ref={ref} {...props} />;
});


export default function SimulateurEmprunteur() {
    const [loanAmount, setLoanAmount] = useState('');
    const [loanDuration, setLoanDuration] = useState('15');
    const [age, setAge] = useState('');
    const [currentRate, setCurrentRate] = useState('');
    const [loanRate, setCurrentLoanRate] = useState('');
    const [subscriptionYear, setSubscriptionYear] = useState('2025');
    const [isAffiner, setIsAffiner] = useState(false);

    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    // const [birthDate, setBirthDate] = useState('');
    const [profilTabagique, setProfilTabagique] = useState('Non Fumeur');
    const [csp, setCsp] = useState('Salarié Cadre');
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [gender, setGender] = useState("Homme");

    const [economies, setEconomies] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [showEconomy, setShowEconomy] = useState<boolean>(false);

    const [callApi, setCallApi] = useState<string>("Tarif");
    const [open, setOpen] = useState(false);

    const texts = [
        "Prise en compte de vos données...",
        "Calcul du coût de l'assurance actuelle...",
        "Calcul du coût de l'assurance avec Julia...",
        "Calcul des économies..."
    ];
    const [index, setIndex] = useState(0);

    // Mise à jour automatique du taux en fonction de l'âge
    useEffect(() => {
        if (age) {
            const ageNum = parseInt(age);
            let rate;

            if (ageNum < 30) {
                rate = 0.25;
            } else if (ageNum < 50) {
                rate = 0.26 + (ageNum - 30) * 0.01; // 30 to 49 → +0.01 per year
            } else if (ageNum < 60) {
                rate = 0.46 + (ageNum - 50) * 0.02; // 50 to 59 → +0.02 per year
            } else {
                rate = 0.85; // 60 and above → Fixed at 0.85
            }

            setCurrentRate(rate.toFixed(2)); // Format to 2 decimal places
        }
    }, [age]);

    const formatAmount = (value: string) => {
        const amount = parseFloat(value.replace(/[^\d]/g, ''));
        return amount.toLocaleString('fr-FR');
    };

    const parseFormattedAmount = (formattedValue: string): number => {
        return parseFloat(formattedValue.replace(/\s/g, '').replace(',', '.'));
    };

    const parseAmount = (value: string) => {
        return value.replace(/[^\d]/g, '');
    };

    const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setLoanAmount(formatAmount(value));
    };

    const calculateInsuranceCosts = () => {
        const amount = parseFloat(parseAmount(loanAmount));
        const duration = parseInt(loanDuration);
        const rate = parseFloat(currentRate);

        const bankCost = Math.round(amount * (rate / 100) * duration);
        const juliaCost = Math.round(bankCost * 0.7); // 30% d'économies en moyenne
        const savings = bankCost - juliaCost;

        return { bankCost, juliaCost, savings };
    };

    const showRes = () => {
        if (!isAffiner) {
            return loanAmount && loanDuration && currentRate && parseInt(age) >= 18 && !loading
        } else {
            return !showError && !loading && showEconomy;
        }
    };

    // Mise à jour automatique du taux en fonction de l'âge
    useEffect(() => {
        if (!isAffiner && (loanAmount && loanDuration && currentRate && parseInt(age) >= 18)) {
            document.querySelector('#results')?.scrollIntoView({ behavior: 'smooth' });
            setLoading(true);

            // Wait for 3 seconds before updating the state
            setTimeout(() => {
                setLoading(false);
                document.querySelector('#results')?.scrollIntoView({ behavior: 'smooth' });
            }, 500);
        }
    }, [loanAmount, loanDuration, currentRate, age, isAffiner]);


    const activeButton = () => {
        return (
            loanAmount && loanDuration && loanRate && firstName && lastName
            && phoneNumberRegex.test(phone) && emailRegEx.test(email) && parseInt(age) >= 18 &&
            profilTabagique && csp && acceptTerms
        ) as boolean;
    };

    const getEconomies = async () => {
        setCallApi("Tarif");
        document.querySelector('#results')?.scrollIntoView({ behavior: 'smooth' });
        setLoading(true);
        setShowError(false);
        setIndex(0); // Reset index at the beginning

        // Start the interval to cycle through texts
        const interval = setInterval(() => {
            setIndex((prevIndex) => {
                const nextIndex = prevIndex + 1;
                if (nextIndex >= texts.length) {
                    clearInterval(interval); // Stop once we reach the end
                    return prevIndex; // Keep the last text visible
                }
                return nextIndex;
            });
        }, 1000);

        const date_naissance = (new Date().getFullYear() - parseInt(age)) + "-01-01";
        const data = {
            date_naissance: date_naissance,
            montant: parseFormattedAmount(loanAmount),
            taux: loanRate,
            duree: (parseInt(loanDuration) * 12).toString(),
            profil_tabagique: profilTabagique,
            csp: csp
        };

        try {
            const res = await AdminService.api_get_economie(data);
            if (!res["erreur"]) {
                setEconomies(res["res"]);
                setShowError(false);
                setShowEconomy(true);
            } else {
                setShowEconomy(false);
                setShowError(true);
            }
        } catch (error) {
            setShowEconomy(false);
            setShowError(true);
        }

        // Wait for the last text to display before stopping loading
        await new Promise((resolve) => setTimeout(resolve, texts.length * 1000));

        setLoading(false); // Stop loading
        document.querySelector('#results')?.scrollIntoView({ behavior: 'smooth' });
    };

    const addRdv = async () => {
        setCallApi("Rdv");

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setLoading(true);
        setShowError(false);

        const newDataAdherents = {
            gender: gender, prenom: firstName, nom: lastName, email: email, telephone: phone,
            accept_data: acceptTerms, type_devis: "DevisEmprunteur", remarques: ""
        };

        newDataAdherents.remarques = `
            age: ${age}, montant: ${loanAmount} €, taux: ${loanRate} %, duree: ${loanDuration} ans
        `;

        const origine = "Création site internet - landing emprunteur";

        await AdminService.api_add_rdv(
            newDataAdherents, origine, "DIAMANT"
        ).then(async () => {
            setOpen(true);
            setLoading(false);
        }).catch(() => {
            setShowError(true);
            setLoading(false);
        });
    };

    const handleClose = () => {
        setOpen(false);
    };

    const formatNumber = (numStr: string) => {
        return numStr.replace(/,/g, ' ');
    };

    const getChampsManquantsOrInvalide = () => {
        const champsManquantsOrInvalides: string[] = [];
        if (!loanAmount || parseFormattedAmount(loanAmount) === 0) {
            champsManquantsOrInvalides.push("montant du prêt");
        }
        if (!loanDuration) {
            champsManquantsOrInvalides.push("durée du prêt");
        }
        if (!loanRate) {
            champsManquantsOrInvalides.push("taux du prêt");
        }
        if (!firstName) {
            champsManquantsOrInvalides.push("prénom");
        }
        if (!lastName) {
            champsManquantsOrInvalides.push("nom");
        }
        if (!phoneNumberRegex.test(phone)) {
            champsManquantsOrInvalides.push("numéro téléphone");
        }
        if (!emailRegEx.test(email)) {
            champsManquantsOrInvalides.push("adresse email");
        }
        if (parseInt(age) < 18) {
            champsManquantsOrInvalides.push("âge");
        }
        if (!profilTabagique) {
            champsManquantsOrInvalides.push("profil tabagique");
        }
        if (!csp) {
            champsManquantsOrInvalides.push("profession");
        }
        if (!acceptTerms) {
            champsManquantsOrInvalides.push("termes et conditions");
        }
        return champsManquantsOrInvalides.length > 0 ? champsManquantsOrInvalides.join(", ") : false;
    };

    return (
        <div className="bg-white simulateur-emprunteur">
            {/* Hero */}
            <Stack mt={12}>
                <div className="bg-blue-600 text-white">
                    <CustomContainer>
                        <div className="max-w-7xl mx-auto py-24">
                            <h2 className="text-4xl font-bold" style={{ color: "white" }}>Simulateurs d'économies</h2>
                            <p className="mt-4 text-blue-100">
                                Estimez vos économies potentielles en quelques clics
                            </p>
                        </div>
                    </CustomContainer>
                </div>
            </Stack>

            {/* Simulator Section */}
            <Stack>
                <CustomContainer>
                    <div className="max-w-7xl mx-auto py-16">
                        <div className="grid gap-8 grid-cols-1 lg:grid-cols-12">
                            {/* Formulaire à gauche - 5 colonnes */}
                            {!isAffiner ? (
                                <div className="lg:col-span-5">
                                    <div className="bg-white rounded-lg shadow-lg p-8">
                                        <h3 className="text-2xl font-bold text-gray-900 mb-8">Calculez vos économies</h3>

                                        <div className="space-y-8">
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700 mb-3 century">
                                                    Montant du prêt (en Euro)
                                                </label>
                                                <div className="grid grid-cols-4 gap-2 mb-3">
                                                    {[
                                                        { value: '50000', label: '50k' },
                                                        { value: '100000', label: '100k' },
                                                        { value: '150000', label: '150k' },
                                                        { value: '200000', label: '200k' },
                                                        { value: '250000', label: '250k' },
                                                        { value: '300000', label: '300k' },
                                                        { value: '400000', label: '400k' },
                                                        { value: '500000', label: '500k' }
                                                    ].map(({ value, label }) => (
                                                        <button
                                                            key={value}
                                                            type="button"
                                                            onClick={() => setLoanAmount(formatAmount(value))}
                                                            className={`century py-2 px-3 text-sm font-medium rounded-md ${loanAmount === formatAmount(value)
                                                                ? 'bg-blue-600 text-white'
                                                                : 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                                                                }`}
                                                        >
                                                            {label}
                                                        </button>
                                                    ))}
                                                </div>
                                                <div className="relative w-full">
                                                    <input
                                                        type="text"
                                                        value={Number.isNaN(parseInt(loanAmount)) ? 0 : loanAmount}
                                                        onChange={handleAmountChange}
                                                        className="century pl-2 pr-6 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                        placeholder="Ou saisissez un montant personnalisé"
                                                    />
                                                    <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">€</span>
                                                </div>
                                            </div>

                                            <div>
                                                <label className="century block text-sm font-medium text-gray-700 mb-3">
                                                    Durée du prêt
                                                </label>
                                                <select
                                                    value={loanDuration}
                                                    onChange={(e) => setLoanDuration(e.target.value)}
                                                    className="century pl-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                >
                                                    <option value="7">7 ans</option>
                                                    <option value="10">10 ans</option>
                                                    <option value="12">12 ans</option>
                                                    <option value="15">15 ans</option>
                                                    <option value="20">20 ans</option>
                                                    <option value="25">25 ans</option>
                                                </select>
                                            </div>

                                            <div>
                                                <label className="century block text-sm font-medium text-gray-700 mb-3">
                                                    Votre âge
                                                </label>
                                                <input
                                                    type="number"
                                                    value={age}
                                                    onChange={(e) => setAge(e.target.value)}
                                                    min="18"
                                                    max="80"
                                                    className="century pl-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                    placeholder="Ex: 35"
                                                />
                                            </div>

                                            <div>
                                                <label className="century block text-sm font-medium text-gray-700 mb-3">
                                                    Année de souscription
                                                </label>
                                                <select
                                                    value={subscriptionYear}
                                                    onChange={(e) => setSubscriptionYear(e.target.value)}
                                                    className="century pl-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                >
                                                    {Array.from({ length: 25 }, (_, i) => 2024 - i).map(year => (
                                                        <option key={year} value={year}>{year}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div>
                                                <label className="century block text-sm font-medium text-gray-700 mb-3">
                                                    Taux actuel (%)
                                                </label>
                                                <input
                                                    type="number"
                                                    value={currentRate}
                                                    onChange={(e) => setCurrentRate(e.target.value)}
                                                    step="0,01"
                                                    className="century pl-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                    placeholder="Ex: 0,35"
                                                />
                                                <p className="mt-2 text-sm text-blue-600">
                                                    * Ce taux est calculé automatiquement selon votre profil et les moyennes du marché.
                                                </p>
                                                <p className="mt-1 text-sm text-gray-500">
                                                    Si vous connaissez votre taux exact, nous vous conseillons de le renseigner pour obtenir une simulation plus précise de vos économies.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="lg:col-span-5">
                                    <div className="bg-white rounded-lg shadow-lg p-8">
                                        <h3 className="text-2xl font-bold text-gray-900 mb-8">Calculez vos économies</h3>

                                        <h4 className='century-bold' style={{ marginBottom: 20 }}>Informations du prêt</h4>

                                        <div className="space-y-8">
                                            {/* Montant du prêt */}
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700 mb-3 century">
                                                    Montant du prêt (en Euro)
                                                </label>
                                                <div className="grid grid-cols-4 gap-2 mb-3">
                                                    {[
                                                        { value: '50000', label: '50k' },
                                                        { value: '100000', label: '100k' },
                                                        { value: '150000', label: '150k' },
                                                        { value: '200000', label: '200k' },
                                                        { value: '250000', label: '250k' },
                                                        { value: '300000', label: '300k' },
                                                        { value: '400000', label: '400k' },
                                                        { value: '500000', label: '500k' }
                                                    ].map(({ value, label }) => (
                                                        <button
                                                            key={value}
                                                            type="button"
                                                            onClick={() => setLoanAmount(formatAmount(value))}
                                                            className={`century py-2 px-3 text-sm font-medium rounded-md ${loanAmount === formatAmount(value)
                                                                ? 'bg-blue-600 text-white'
                                                                : 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                                                                }`}
                                                        >
                                                            {label}
                                                        </button>
                                                    ))}
                                                </div>
                                                <div className="relative w-full">
                                                    <input
                                                        type="text"
                                                        value={Number.isNaN(parseInt(loanAmount)) ? 0 : loanAmount}
                                                        onChange={handleAmountChange}
                                                        className="century pl-2 pr-6 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                        placeholder="Ou saisissez un montant personnalisé"
                                                    />
                                                    <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">€</span>
                                                </div>
                                            </div>

                                            {/* Durée du prêt */}
                                            <div>
                                                <label className="century block text-sm font-medium text-gray-700 mb-3">
                                                    Durée du prêt
                                                </label>
                                                <select
                                                    value={loanDuration}
                                                    onChange={(e) => setLoanDuration(e.target.value)}
                                                    className="century pl-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                >
                                                    <option value="7">7 ans</option>
                                                    <option value="10">10 ans</option>
                                                    <option value="12">12 ans</option>
                                                    <option value="15">15 ans</option>
                                                    <option value="20">20 ans</option>
                                                    <option value="25">25 ans</option>
                                                </select>
                                            </div>

                                            <div>
                                                <label className="century block text-sm font-medium text-gray-700 mb-3">
                                                    Taux actuel (%)
                                                </label>
                                                <input
                                                    type="number"
                                                    value={currentRate}
                                                    onChange={(e) => setCurrentRate(e.target.value)}
                                                    step="0,01"
                                                    className="century pl-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                    placeholder="Ex: 0,35"
                                                />
                                            </div>

                                            <h4 className='century-bold'>Vos informations</h4>

                                            {/* Taux actuel */}
                                            <div>
                                                <label className="century block text-sm font-medium text-gray-700 mb-3">
                                                    Taux du prêt (%)
                                                </label>
                                                <input
                                                    type="number"
                                                    value={loanRate}
                                                    onChange={(e) => setCurrentLoanRate(e.target.value)}
                                                    step="0.01"
                                                    className="century pl-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                    placeholder="Ex: 0.35"
                                                />
                                            </div>

                                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8">
                                                {/* Nom */}
                                                <div>
                                                    <label className="century block text-sm font-medium text-gray-700 mb-3">
                                                        Nom
                                                    </label>
                                                    <input
                                                        type="text"
                                                        value={lastName}
                                                        onChange={(e) => setLastName(e.target.value)}
                                                        className="century pl-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                        placeholder="Ex: Dupont"
                                                    />
                                                </div>

                                                {/* Prénom */}
                                                <div>
                                                    <label className="century block text-sm font-medium text-gray-700 mb-3">
                                                        Prénom
                                                    </label>
                                                    <input
                                                        type="text"
                                                        value={firstName}
                                                        onChange={(e) => setFirstName(e.target.value)}
                                                        className="century pl-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                        placeholder="Ex: Jean"
                                                    />
                                                </div>
                                            </div>

                                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8">
                                                {/* Gender */}
                                                <div>
                                                    <label className="century block text-sm font-medium text-gray-700 mb-3">
                                                        Genre
                                                    </label>
                                                    <select
                                                        value={gender}
                                                        onChange={(e) => setGender(e.target.value)}
                                                        className="century pl-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                    >
                                                        <option value="Homme">Homme</option>
                                                        <option value="Femme">Femme</option>
                                                    </select>
                                                </div>

                                                {/* Age */}
                                                <div>
                                                    <label className="century block text-sm font-medium text-gray-700 mb-3">
                                                        Votre âge
                                                    </label>
                                                    <input
                                                        type="number"
                                                        value={age}
                                                        onChange={(e) => setAge(e.target.value)}
                                                        min="18"
                                                        max="80"
                                                        className="century pl-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                        placeholder="Ex: 35"
                                                    />
                                                </div>
                                            </div>


                                            {/* Email */}
                                            <div>
                                                <label className="century block text-sm font-medium text-gray-700 mb-3">
                                                    Email
                                                </label>
                                                <input
                                                    type="email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    className="century pl-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                    placeholder="Ex: exemple@email.com"
                                                />
                                            </div>

                                            {/* Téléphone */}
                                            <div>
                                                <label className="century block text-sm font-medium text-gray-700 mb-3">
                                                    Téléphone
                                                </label>
                                                <input
                                                    type="tel"
                                                    value={phone}
                                                    onChange={(e) => setPhone(e.target.value)}
                                                    className="century pl-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                    placeholder="Ex: +33 6 12 34 56 78"
                                                />
                                            </div>

                                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8">
                                                {/* Profil tabagique */}
                                                <div>
                                                    <label className="century block text-sm font-medium text-gray-700 mb-3">
                                                        Profil tabagique
                                                    </label>
                                                    <select
                                                        value={profilTabagique}
                                                        onChange={(e) => setProfilTabagique(e.target.value)}
                                                        className="century pl-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                    >
                                                        <option value="Fumeur">Fumeur</option>
                                                        <option value="Non Fumeur">Non fumeur</option>
                                                    </select>
                                                </div>

                                                {/* CSP */}
                                                <div>
                                                    <label className="century block text-sm font-medium text-gray-700 mb-3">
                                                        Statut professionnel
                                                    </label>
                                                    <select
                                                        value={csp}
                                                        onChange={(e) => setCsp(e.target.value)}
                                                        className="century pl-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                    >
                                                        <option value="Salarié Cadre">Salarié Cadre</option>
                                                        <option value="Salarié non cadre">Salarié non cadre</option>
                                                        <option value="Enseignant">Enseignant</option>
                                                        <option value="Fonctionnaire">Fonctionnaire</option>
                                                        <option value="Chef d'entreprise">Chef d'entreprise</option>
                                                        <option value="Commerçant">Commerçant</option>
                                                        <option value="Artisan">Artisan</option>
                                                        <option value="Agriculteur">Agriculteur</option>
                                                        <option value="Profession libérale">Profession libérale</option>
                                                        <option value="VRP">VRP</option>
                                                        <option value="Professionnel du spectacle">Professionnel du spectacle</option>
                                                        <option value="Forain">Forain</option>
                                                        <option value="Etudiant">Etudiant</option>
                                                        <option value="Retraité">Retraité</option>
                                                        <option value="Recherche d'emploi">Recherche d'emploi</option>
                                                        <option value="Au foyer">Au foyer</option>
                                                        <option value="Sans profession">Sans profession</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="flex items-center mt-4">
                                                <input
                                                    type="checkbox"
                                                    id="terms"
                                                    checked={acceptTerms}
                                                    onChange={(e) => setAcceptTerms(e.target.checked)}
                                                    className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                                />
                                                <label htmlFor="terms" className="ml-2 text-sm text-gray-700">
                                                    J'accepte que les données collectées ci-dessus soient utilisées pour la création de mon devis et accepte qu'on me recontacte dans le cadre de cette démarche.
                                                </label>
                                            </div>

                                            {/* Recevoir mes économies */}
                                            <button
                                                onClick={() => getEconomies()}
                                                className={`w-full py-3 px-4 rounded-md font-medium text-white ${activeButton()
                                                    ? 'bg-blue-600 hover:bg-blue-700'
                                                    : 'bg-gray-400 cursor-not-allowed'
                                                    }`}
                                                disabled={!activeButton()}
                                            >
                                                Recevoir mes économies
                                            </button>
                                            {/* Informations sur les champs manquants */}
                                            {
                                                getChampsManquantsOrInvalide() && (
                                                    <Stack>
                                                        <small className='text-red' style={{ color: "red" }}>
                                                            Les champs suivants sont manquants ou invalides: {getChampsManquantsOrInvalide()}.
                                                        </small>
                                                    </Stack>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* Résultats à droite - 7 colonnes */}
                            <div id="results" className="lg:col-span-7 space-y-8">
                                {((): any => {
                                    if (showRes()) {
                                        return (
                                            <>
                                                {/* Encart des économies potentielles */}
                                                <div className="bg-white rounded-lg shadow-lg p-8">
                                                    <h3 className="text-lg font-medium text-gray-900 mb-4">
                                                        Vos économies {!isAffiner ? "potentielles" : ''}
                                                    </h3>
                                                    {(() => {
                                                        const { bankCost, juliaCost, savings } = calculateInsuranceCosts();
                                                        return (
                                                            <>
                                                                <div className="space-y-3">
                                                                    <div className="flex items-center justify-between text-gray-600">
                                                                        <span>Coût total avec votre banque :</span>
                                                                        <span className="font-medium text-red-600">
                                                                            {!isAffiner ? bankCost.toLocaleString('fr-FR') + ' €' : formatNumber(economies['tarif_banque'])}
                                                                        </span>
                                                                    </div>
                                                                    <div className="flex items-center justify-between text-gray-600">
                                                                        <span>Coût total avec Julia :</span>
                                                                        <span className="font-medium text-green-600">
                                                                            {!isAffiner ? juliaCost.toLocaleString('fr-FR') + ' €' : formatNumber(economies['min_crd'])}
                                                                        </span>
                                                                    </div>
                                                                    <div className="pt-3 border-t flex items-center justify-between">
                                                                        <span className="font-medium">Économies totales :</span>
                                                                        <span className="text-2xl font-bold text-blue-600">
                                                                            {!isAffiner ? savings.toLocaleString('fr-FR') + ' €' : formatNumber(economies['economie_crd'])}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                {!isAffiner && (
                                                                    <p className="mt-4 text-sm text-gray-500">
                                                                        * Cette estimation est basée sur les données moyennes du marché et pourra être affinée lors de l'étude personnalisée de votre dossier.
                                                                    </p>
                                                                )}
                                                            </>
                                                        );
                                                    })()}
                                                </div>

                                                {/* Graphique */}

                                                {
                                                    !isAffiner && (
                                                        <div className="bg-white rounded-lg shadow-lg p-8">

                                                            <h3 className="text-lg font-medium text-gray-900 mb-4">
                                                                Estimation de vos économies
                                                            </h3>
                                                            <InsuranceCostChart
                                                                loanAmount={parseFloat(parseAmount(loanAmount))}
                                                                loanDuration={parseInt(loanDuration)}
                                                                currentRate={parseFloat(currentRate)}
                                                                proposedRate={parseFloat(currentRate) * 0.7}
                                                                subscriptionYear={parseInt(subscriptionYear)}
                                                            />
                                                            <button
                                                                onClick={() => setIsAffiner(true)}
                                                                className={`w-full py-3 px-4 rounded-md font-medium text-white ${loanAmount && loanDuration && currentRate && age
                                                                    ? 'bg-blue-600 hover:bg-blue-700'
                                                                    : 'bg-gray-400 cursor-not-allowed'
                                                                    }`}
                                                            >
                                                                Calculer mon économie réelle
                                                            </button>

                                                        </div>
                                                    )
                                                }
                                                {
                                                    showEconomy && (
                                                        <div className="bg-white rounded-lg shadow-lg p-8">
                                                            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
                                                                <h3 className="text-2xl font-bold text-gray-900 mb-4">
                                                                    Prêt à concrétiser vos économies ?
                                                                </h3>
                                                                <p className="century text-gray-600 mb-8">
                                                                    Nos experts sont là pour vous accompagner et optimiser vos contrats.
                                                                </p>
                                                                <button onClick={addRdv} className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700">
                                                                    Parler à un expert
                                                                    <ChevronRight className="ml-2 h-5 w-5" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    }
                                    if (showError) {
                                        return (
                                            <Stack alignItems={"center"}>
                                                <Stack bgcolor={"#f8d7da"} py={2.5} px={2} borderRadius={1} my={2} borderColor={"#f5c6cb"}>
                                                    <small className='m-0 century align-center' style={{ color: "#7c2932", fontSize: 15.5, display: "flex", alignItems: "center" }}>
                                                        <IoWarning size={22} /> Une erreur s'est produite lors {callApi === 'Tarif' ? "de la récupération des tarifs" : "de création de votre rendez-vous"}
                                                    </small>
                                                </Stack>
                                            </Stack>
                                        )
                                    }
                                    if (loading) {
                                        return (
                                            <Stack alignItems={"center"} justifyContent={"center"}>
                                                <Loading id="loading" color={"#2563eb"} size={150} />
                                                {
                                                    callApi === "Tarif" && (
                                                        <h4 className='century-bold' style={{ fontSize: 20, color: "#2563eb", marginTop: 20 }}>
                                                            {texts[index]}
                                                        </h4>
                                                    )
                                                }
                                            </Stack>
                                        )
                                    }
                                })()}
                            </div>

                            {/* Info Cards en bas - 12 colonnes */}
                            <div className="lg:col-span-12 grid grid-cols-1 md:grid-cols-2 gap-8">
                                <div className="bg-blue-50 rounded-lg p-6">
                                    <h4 style={{ fontSize: 28 }} className="century-bold flex items-center text-lg font-medium text-blue-900 mb-4">
                                        <Calculator className="h-6 w-6 mr-2" />
                                        Comment ça marche ?
                                    </h4>
                                    <ul className="space-y-4">
                                        <li className="flex items-start">
                                            <ChevronRight className="h-5 w-5 text-blue-500 mr-2 flex-shrink-0" />
                                            <span>Remplissez le simulateur avec vos informations actuelles</span>
                                        </li>
                                        <li className="flex items-start">
                                            <ChevronRight className="h-5 w-5 text-blue-500 mr-2 flex-shrink-0" />
                                            <span>Obtenez une estimation de vos économies potentielles</span>
                                        </li>
                                        <li className="flex items-start">
                                            <ChevronRight className="h-5 w-5 text-blue-500 mr-2 flex-shrink-0" />
                                            <span>Un expert vous contacte pour affiner l'analyse</span>
                                        </li>
                                    </ul>
                                </div>

                                <div className="bg-green-50 rounded-lg p-6">
                                    <h4 style={{ fontSize: 28 }} className="century-bold flex items-center text-lg font-medium text-green-900 mb-4">
                                        <Euro className="h-6 w-6 mr-2" />
                                        Pourquoi changer d'assurance ?
                                    </h4>
                                    <ul className="space-y-4">
                                        <li className="flex items-start">
                                            <ChevronRight className="h-5 w-5 text-green-500 mr-2 flex-shrink-0" />
                                            <span>Économisez jusqu'à 30% sur votre assurance de prêt</span>
                                        </li>
                                        <li className="flex items-start">
                                            <ChevronRight className="h-5 w-5 text-green-500 mr-2 flex-shrink-0" />
                                            <span>Bénéficiez de garanties équivalentes ou supérieures</span>
                                        </li>
                                        <li className="flex items-start">
                                            <ChevronRight className="h-5 w-5 text-green-500 mr-2 flex-shrink-0" />
                                            <span>Profitez d'un accompagnement personnalisé</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </CustomContainer>
            </Stack>
            <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={handleClose}>
                <DialogTitle>
                    <p className="m-0 text-bold" style={{ fontSize: 25 }}>Merci {firstName} !</p>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText component={"div"}>
                        <p>Nous avons bien enregistré vos informations.
                            <br />Par volonté de vous offrir la meilleure expérience client possible, un conseiller Julia va vous appeler.
                            <br />Merci de votre confiance 😀
                        </p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className="btn-precedent" onClick={handleClose} variant="text">
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}