import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import colors from "../../colors/colors";


export default function SuccessMessage(props: any) {
    const { prenom, showOrHideDialog, dataHomepage, notShowAction } = props;
    return (
        <>
            <DialogTitle component={"div"}>
                <h4 className="m-0 century-bold" style={{ color: "#444" }}>Merci {prenom} !</h4>
            </DialogTitle>
            <DialogContent>
                <DialogContentText component={"div"}>
                    <p className='century-bold'>Nous avons bien enregistré les informations vous concernant.
                        <br />Par volonté de vous offrir la meilleure expérience client possible, un conseiller Julia va vous appeler.
                        <br />Merci de votre confiance 😀
                    </p>
                </DialogContentText>
            </DialogContent>
            {
                !notShowAction && (
                    <DialogActions>
                        <Button
                            sx={{ color: dataHomepage ? dataHomepage["secondary_color"] : colors.pink }}
                            className={`${!dataHomepage ? `btn-precedent` : `btn-precedent-branding`}`}
                            onClick={() => showOrHideDialog(false)} variant="text">
                            <span className='century-bold'>Fermer</span>
                        </Button>
                    </DialogActions>
                )
            }
        </>
    );
};