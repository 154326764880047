/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import {
    Box, Button, Grid, Stack, Slider, RadioGroup,
    FormControlLabel, Radio, FormControl, TextField, Checkbox, Container,
    useMediaQuery, useTheme,
    ThemeProvider,
    CssBaseline,
    createTheme,
    Slide
} from '@mui/material';
import { CustomContainer } from '../../components/CustomContainer/CustomContainer';
import Loading from "../../components/Loading/Loading";
import { BsFolderPlus, BsArrowRepeat } from "react-icons/bs";
import colors from '../../colors/colors';
import { LuUserPlus2 } from "react-icons/lu";
import { PiTrashSimpleThin } from "react-icons/pi";
import Label from '../../components/Label/Label';
import { AdminService } from '../../services/services';
import { GiReceiveMoney } from "react-icons/gi";
import { IoWarning } from 'react-icons/io5';
import { useLocation, useNavigate } from 'react-router-dom';
import oneIcon from "../../assets/icons/one.svg";
import twoIcon from "../../assets/icons/two.svg";
import threeIcon from "../../assets/icons/three.svg";
import ugipLogo from "../../assets/logos/UGIP_ASSURANCES_Logo_horizontal_RVB 1.svg";
import aprilLogo from "../../assets/logos/APRIL_QUADRI_BLEU_RVB[1] 1.svg";
import neolianeLogo from "../../assets/logos/Logo_Néoliane_RVB.png";
import apviaLogo from "../../assets/logos/apivia.png";
import generaliLogo from "../../assets/logos/1200px-Assicurazioni_Generali_(logo).svg.png";
import micLogo from "../../assets/logos/Logo-MIC_courrier.png";
import SatisfactionClients from '../../components/SatisfactionClients/SatisfactionClients';
import SuccessMessage from '../../components/SuccessMessage/SuccessMessage';
import { motion } from "framer-motion";
import './ParcoursEmprunteurSimple.scss';


const phoneNumberRegex = new RegExp(`^(0|(00|\\+)33)[123456789][0-9]{8}$`);
const emailRegEx = new RegExp('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}');

const DEVIS = "__devis__";
const FORMULAIRE = "__formulaire__";
const ECONOMIE = "__economie__"
const NOUVEAU = "__nouveau__";
const CHANGER = "__changer__";

interface CustomWindow extends Window {
    dataLayer: any[],
};

const slideVariants = {
    hidden: { x: "100%", opacity: 0 },
    visible: { x: 0, opacity: 1 },
    exit: { x: "-100%", opacity: 0 },
};


function ParcoursEmprunteurSimple() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const navigate = useNavigate();
    const theme = useTheme();

    const [loadCustom, setLoadCustom] = useState<boolean>(
        !["", undefined].includes(location.pathname.split("/")[2]) || typeof queryParams.get('branding') === 'string'
    );
    const [isCustom] = useState<boolean>(!["", undefined].includes(location.pathname.split("/")[2]));
    const [loading, setLoading] = useState<boolean>(false);
    const [activeSection, setActiveSection] = useState<string>(DEVIS);
    const [activeProjet, setActiveProjet] = useState<string>(CHANGER);
    const [showEconomy, setShowEconomy] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [adherentLst, setAdherentLst] = useState<any[]>([{ age: 40 }]);
    const [montant, setMontant] = useState<number>(200000);
    const [taux, setTaux] = useState<number>(5.2);
    const [duree, setDuree] = useState<number>(15);
    const [, setOpenDialog] = useState<boolean>(false);
    const [economies, setEconomies] = useState<any>();
    const [showError, setShowError] = useState<boolean>(false);
    const [showErrorCustom, setShowErrorCustom] = useState<boolean>(false);

    const defaultDataAdherents = { gender: "", prenom: "", nom: "", email: "", telephone: "", accept_data: false, type_devis: "DevisEmprunteur", remarques: "" };
    const [dataAdherents, setDataAdherents] = useState<any>(defaultDataAdherents);

    const [showFloatingButton, setShowFloatingButton] = useState(false);
    const [lastScrollPosition, setLastScrollPosition] = useState(0);

    const matches = useMediaQuery(theme.breakpoints.down('md'));

    const [isBranding] = useState<null | string | boolean>(queryParams.get('branding'));
    const [dataHomepage, setDataHomepage] = useState<any>({});


    useEffect(() => {
        if ((!["", undefined].includes(location.pathname.split("/")[2])) || isBranding) {
            getHomePage();
        }

        const appendGtmScript = () => {
            const scriptElement = document.createElement('script');
            scriptElement.id = 'gtm-script';
            scriptElement.innerHTML = `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-WMZCJLG5');
            `;

            const headElement = document.getElementById('head');
            if (headElement) {
                headElement.appendChild(scriptElement);
            }
        };
        if (!isBranding) { appendGtmScript() };

    }, [location.pathname]);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPosition = window.pageYOffset;
            const buttonElement = document.getElementById('go-formulaire') as any;
            const buttonPosition = buttonElement?.offsetTop;

            // If scrolling down and passed the button position
            if (currentScrollPosition > lastScrollPosition && currentScrollPosition > buttonPosition - 100) {
                setShowFloatingButton(true);
            }
            // If scrolling up and not yet passed the button position
            else if (currentScrollPosition < lastScrollPosition && currentScrollPosition < buttonPosition + 400) {
                setShowFloatingButton(false);
            }

            setLastScrollPosition(currentScrollPosition);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [lastScrollPosition]);


    const formTheme = createTheme({
        palette: {
            primary: {
                main: dataHomepage['primary_color'] || colors.blue1,
            }
        },
        typography: {
            fontFamily: 'century'
        },
    });

    const handleAge = (index: number, value: any) => {
        checkIsEditing();
        const newAdherentList = [...adherentLst];
        newAdherentList[index] = { ...newAdherentList[index], age: +value };
        setAdherentLst(newAdherentList);
    };

    const removeAdherent = (index: number) => {
        checkIsEditing();
        const newAdherentList = adherentLst.filter((_, i) => i !== index);
        setAdherentLst(newAdherentList);
    };

    const addAdherent = () => {
        checkIsEditing();
        const newAdherentList = [...adherentLst, { age: 18 }];
        setAdherentLst(newAdherentList);
    };

    const handleMontant = (value: any) => {
        checkIsEditing();
        const newMontant = +value;
        setMontant(newMontant);
    };

    const handleTaux = (value: any) => {
        checkIsEditing();
        const newTaux = +value;
        setTaux(newTaux);
    };

    const handleDuree = (value: any) => {
        checkIsEditing();
        const newDuree = +value;
        setDuree(newDuree);
    };

    const handleDataAdherents = (key: string, value: any) => {
        setDataAdherents((prevState: any) => ({
            ...prevState,
            [key]: value
        }));
    };

    const isAllDataValid = () => {
        return (
            dataAdherents["nom"] !== "" && dataAdherents["prenom"] !== "" &&
            phoneNumberRegex.test(dataAdherents["telephone"]) && emailRegEx.test(dataAdherents["email"]) && dataAdherents["accept_data"]
        )
    };

    const addRdv = async () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setLoading(true);
        setShowError(false);

        const newDataAdherents = { ...dataAdherents };
        newDataAdherents.remarques = `date_naissance: ${getBirthdateFromAge(adherentLst[0].age)}, montant: ${montant} €, taux: ${taux} %, duree: ${duree} ans`;

        const utms = ["utm_medium", "utm_source", "utm_campaign", "utm_content", "utm_adname"];
        utms.forEach((utm, _index) => {
            if (queryParams.get(utm)) newDataAdherents[utm] = queryParams.get(utm);
        });

        // On envoie l'opt-in si branding
        if (isBranding) {
            newDataAdherents['content'] = `
                J'accepte que les données collectées ci-dessus soient utilisées pour la création de mon devis d’assurance de prêt et accepte qu'on me recontacte par téléphone dans le cadre de cette démarche.
                Pour plus d'information, cliquez-ici ${dataHomepage["privacy_url"]}
            `;
        };

        const origine = queryParams.get("utm_medium") ?? "Création site internet - landing emprunteur";
        const isLanding = origine === "Création site internet - landing emprunteur";

        await AdminService.api_add_rdv(
            newDataAdherents, 
            (isLanding && isBranding) ? origine + ` - ${dataHomepage["nom"]}` : origine, 
            isBranding ? dataHomepage["categorie"] : "DIAMANT"
        ).then(async () => {
            // showOrHideDialog(true);
            getTarif();
            if (typeof window !== 'undefined' && isBranding) {
                const customWindow = window as unknown as CustomWindow;
                customWindow?.dataLayer?.push({
                    'event': 'formSubmit.Success'
                });
            }
        }).catch(() => {
            setShowError(true);
            setLoading(false);
        });
    };

    const getBirthdateFromAge = (age: number) => {
        const today = new Date();
        const birthYear = today.getFullYear() - age;
        const birthdate = new Date(birthYear, today.getMonth(), today.getDate());
        const formattedBirthdate = `${birthdate.getFullYear()}-${((birthdate.getMonth() + 1) + '').padStart(2, '0')}-${(birthdate.getDate() + '').padStart(2, '0')}`;
        return formattedBirthdate;
    };

    const getTarif = async () => {
        setLoading(true);
        setShowError(false);
        const data = {
            age: adherentLst[0].age, montant: montant, taux: taux, duree: duree
        };
        await AdminService.api_get_devis_simple(data).then((res) => {
            if (!res["erreur"]) {
                setActiveSection(ECONOMIE);
                setEconomies(res["res"]);
                setShowError(false);
                setShowEconomy(true);
            } else {
                setShowEconomy(false);
                setShowError(true);
            }
            setLoading(false);
        }).catch(() => {
            setLoading(false);
            setShowEconomy(false);
            setShowError(true);
        });
        setIsEditing(false);
    };

    const showOrHideDialog = async (status: boolean) => {
        if (status) {
            setOpenDialog(true);
        } else {
            setOpenDialog(false);
            setDataAdherents(defaultDataAdherents);
            setActiveSection(DEVIS);
            setShowEconomy(false);
        }
    };

    const getHomePage = async () => {
        let homeUrl: string = isBranding ? `${location.pathname}?branding=${isBranding?.toString().toLowerCase()}` : location.pathname;
        await AdminService.api_get_home_page(homeUrl).then((data: any) => {
            if (data['erreur']) {
                navigate("/");
            } else {
                setDataHomepage(data);
                setLoadCustom(false);
            }
        }).catch(() => {
            setShowErrorCustom(true);
        });
    };

    const checkIsEditing = () => {
        if (showEconomy && !isEditing) setIsEditing(true);
    };

    const formatNumberWithCommas = (number: number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };


    return (
        <Container maxWidth={false} className='px-0' style={{ overflowX: "hidden", background: `url(${dataHomepage["background_image"]}) no-repeat center / cover` }}>
            <CustomContainer>
                <Stack pt={{ md: !queryParams.get('partenaire') ? 20 : 10, xs: !queryParams.get('partenaire') ? 17 : 7 }} pb={{ md: !queryParams.get('partenaire') ? 4 : 10, xs: 0 }}>
                    {((): any => {
                        if (loadCustom) {
                            return (
                                <Box><Loading color={dataHomepage["primary_color"] || colors.blue1} /></Box>
                            )
                        }
                        if (!loadCustom && showErrorCustom) {
                            return (
                                <Stack alignItems={"center"}>
                                    <Stack bgcolor={"#f8d7da"} py={2.5} px={2} borderRadius={1} my={2} borderColor={"#f5c6cb"}>
                                        <small className='m-0 century align-center' style={{ color: "#7c2932", fontSize: 15.5, display: "flex", alignItems: "center" }}>
                                            <IoWarning size={22} /> Une erreur s'est produite lors du chargement de la page
                                        </small>
                                    </Stack>
                                </Stack>
                            )
                        }
                        if (activeSection === DEVIS && !loadCustom) {
                            return (
                                <motion.div
                                    variants={slideVariants}
                                    initial="hidden"
                                    animate="visible"
                                    exit="exit"
                                    transition={{ duration: 1 }}
                                >
                                    <Box>
                                        <Stack>
                                            <h3 className="text-center m-0">
                                                {
                                                    dataHomepage["titre"] ? (
                                                        <span style={{ color: dataHomepage["secondary_color"] || colors.dark2 }}>
                                                            {dataHomepage["titre"]}
                                                        </span>
                                                    ) : (
                                                        <span>
                                                            <span style={{ color: dataHomepage["secondary_color"] || colors.pink }}>
                                                                Réalisez des économies </span>sur votre assurance de prêt
                                                        </span>
                                                    )
                                                }
                                            </h3>
                                            <p className="text-center">
                                                {
                                                    dataHomepage["paragraphe_1"] ? (
                                                        <span>{dataHomepage["paragraphe_1"]}</span>
                                                    ) : (
                                                        <span>
                                                            Remplissez les informations ci-dessous pour calculer combien vous pouvez économiser sur votre assurance emprunteur.
                                                        </span>
                                                    )
                                                }
                                            </p>
                                        </Stack>
                                        <Grid container justifyContent={"center"} spacing={2} alignItems={"stretch"} mt={1.5}>
                                            <Grid container item md={3} sm={6} xs={12} justifyContent={"center"}>
                                                <Stack className="card-engagement" width={"100%"} p={2} borderRadius={2.5}>
                                                    <p className="century-bold m-0 text-center">Votre projet</p>
                                                    <Stack mt={2} spacing={2}>
                                                        <Stack
                                                            style={{
                                                                border: `${activeProjet === NOUVEAU ? '0' : '1'}px solid ${isBranding ? dataHomepage["primary_color"] : colors.blue1}`,
                                                                backgroundColor: activeProjet === NOUVEAU ? `${isBranding ? dataHomepage["primary_color"] : colors.blue1}` : "transparent",
                                                                cursor: activeProjet === NOUVEAU ? 'default' : 'pointer'
                                                            }}
                                                            className={`card-projet`}
                                                            onClick={() => setActiveProjet(NOUVEAU)} alignItems={"center"} gap={0.8}>
                                                            <BsFolderPlus style={{ color: activeProjet === NOUVEAU ? `white` : `${isBranding ? dataHomepage["primary_color"] : colors.blue1}` }} />
                                                            <p className='m-0' style={{ fontSize: 15, color: activeProjet === NOUVEAU ? `white` : `${isBranding ? dataHomepage["primary_color"] : colors.blue1}` }}>
                                                                Assurer un nouveau crédit
                                                            </p>
                                                        </Stack>
                                                        <Stack
                                                            style={{
                                                                border: `${activeProjet === CHANGER ? '0' : '1'}px solid ${isBranding ? dataHomepage["primary_color"] : colors.blue1}`,
                                                                backgroundColor: activeProjet === CHANGER ? `${isBranding ? dataHomepage["primary_color"] : colors.blue1}` : "transparent",
                                                                cursor: activeProjet === CHANGER ? 'default' : 'pointer'
                                                            }}
                                                            className={`card-projet`}
                                                            onClick={() => setActiveProjet(CHANGER)} alignItems={"center"} gap={0.8}>
                                                            <BsArrowRepeat style={{ color: activeProjet === CHANGER ? `white` : `${isBranding ? dataHomepage["primary_color"] : colors.blue1}` }} />
                                                            <p className='m-0' style={{ fontSize: 15, color: activeProjet === CHANGER ? `white` : `${isBranding ? dataHomepage["primary_color"] : colors.blue1}` }}>
                                                                Changer d'assurance
                                                            </p>
                                                        </Stack>
                                                    </Stack>
                                                </Stack>
                                            </Grid>
                                            <Grid container item md={3} sm={6} xs={12} justifyContent={"center"}>
                                                <Stack className="card-engagement" width={"100%"} p={3} borderRadius={2.5} justifyContent={"space-between"}>
                                                    <Stack>
                                                        <p className="century-bold m-0 text-center">Votre âge</p>
                                                        <Stack mt={2}>
                                                            {
                                                                adherentLst.map((adherent, index) => (
                                                                    <Stack width={"100%"} justifyContent={"center"} key={adherent}>
                                                                        <p className='m-0 text-center century-bold'><span className='text-bold' style={{ fontSize: 25 }}>{adherent.age}</span> ans</p>
                                                                        <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} spacing={1}>
                                                                            <Slider key={index} min={18} max={80} step={1} value={adherent.age} sx={{ height: 5, color: !isBranding ? colors.blue1 : dataHomepage["primary_color"] }}
                                                                                onChange={(event: any) => { handleAge(index, event?.target?.value) }}
                                                                            />
                                                                            {adherentLst.length > 1 && (<PiTrashSimpleThin className='cursor-pointer' onClick={() => removeAdherent(index)} />)}
                                                                        </Stack>
                                                                    </Stack>
                                                                ))
                                                            }
                                                        </Stack>
                                                    </Stack>
                                                    {adherentLst.length <= 3 && (
                                                        <Stack width={"100%"} alignItems={"end"} mt={3}>
                                                            <Stack onClick={() => addAdherent()} className='cursor-pointer' direction={"row"} alignItems={"center"} spacing={0.4} color={!isBranding ? colors.blue1 : dataHomepage["primary_color"]}>
                                                                <LuUserPlus2 className='cursor-pointer' />
                                                                <small className='text-bold' style={{ fontSize: 12 }}>Ajouter un emprunteur</small>
                                                            </Stack>
                                                        </Stack>
                                                    )}
                                                </Stack>
                                            </Grid>
                                            <Grid container item md={3} sm={6} xs={12} justifyContent={"center"}>
                                                <Stack className="card-engagement" width={"100%"} p={3} borderRadius={2.5}>
                                                    <p className="century-bold m-0 text-center">Prêt à assurer</p>
                                                    <Stack mt={2}>
                                                        <Stack width={"100%"}>
                                                            <Stack direction={"row"} alignItems={"center"} justifyContent={'space-between'} width={"100%"}>
                                                                <small>Montant</small>
                                                                <p className='m-0 text-center century-bold'><span className='text-bold' style={{ fontSize: 25 }}>{formatNumberWithCommas(montant)}</span> €</p>
                                                            </Stack>
                                                            <Stack alignItems={"center"} justifyContent={"center"} spacing={1}>
                                                                <Slider min={500} max={1000000} step={100} value={montant} sx={{ height: 5, color: !isBranding ? colors.blue1 : dataHomepage["primary_color"] }}
                                                                    onChange={(event: any) => { handleMontant(event?.target?.value) }}
                                                                />
                                                            </Stack>
                                                        </Stack>
                                                        <Stack width={"100%"}>
                                                            <Stack direction={"row"} alignItems={"center"} justifyContent={'space-between'} width={"100%"}>
                                                                <small>Taux</small>
                                                                <p className='m-0 text-center century-bold'><span className='text-bold' style={{ fontSize: 25 }}>{taux}</span> %</p>
                                                            </Stack>
                                                            <Stack alignItems={"center"} justifyContent={"center"} spacing={1}>
                                                                <Slider min={1} max={10} step={.1} value={taux} sx={{ height: 5, color: !isBranding ? colors.blue1 : dataHomepage["primary_color"] }}
                                                                    onChange={(event: any) => { handleTaux(event?.target?.value) }}
                                                                />
                                                            </Stack>
                                                        </Stack>
                                                    </Stack>
                                                </Stack>
                                            </Grid>
                                            <Grid container item md={3} sm={6} xs={12} justifyContent={"center"}>
                                                <Stack className="card-engagement" width={"100%"} p={3} borderRadius={2.5}>
                                                    <p className="century-bold m-0 text-center">Durée du prêt</p>
                                                    <Stack width={"100%"} mt={2}>
                                                        <p className='m-0 text-center century-bold'><span className='text-bold' style={{ fontSize: 25 }}>{duree}</span> ans</p>
                                                        <Stack alignItems={"center"} justifyContent={"center"} spacing={1}>
                                                            <Slider min={1} max={30} step={1} value={duree} sx={{ height: 5, color: !isBranding ? colors.blue1 : dataHomepage["primary_color"] }}
                                                                onChange={(event: any) => { handleDuree(event?.target?.value) }}
                                                            />
                                                        </Stack>
                                                    </Stack>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                        <Stack mt={4} alignItems={"center"}>
                                            {
                                                <>
                                                    {
                                                        dataHomepage["paragraphe_2"] ? (
                                                            <p className='text-bold text-center'>{dataHomepage["paragraphe_2"]}</p>
                                                        ) : (
                                                            <p className='text-bold text-center'>
                                                                Indiquez vos coordonnées pour découvrir vos économies et afin que nous puissions vous contacter.
                                                            </p>
                                                        )
                                                    }
                                                    <Button variant="contained" onClick={() => { setActiveSection(FORMULAIRE); window.scroll(0, 0) }}
                                                        id='go-formulaire'
                                                        sx={{
                                                            my: 2,
                                                            zIndex: (showFloatingButton && matches) ? 100000000 : 1,
                                                            backgroundColor: dataHomepage["primary_color"] || colors.blue1,
                                                            '&:hover': {
                                                                backgroundColor: dataHomepage["primary_color"] || colors.blue2
                                                            }
                                                        }}
                                                        style={{ position: (showFloatingButton && matches) ? 'fixed' : 'relative', bottom: (showFloatingButton && matches) ? '1px' : 'auto' }}
                                                        className={`${!isBranding ? `btn-suivant` : `btn-suivant-branding`}`}
                                                        disabled={loading}
                                                    >
                                                        {isEditing ? <>Recalculer mes économies</> : <>{dataHomepage["cta1"] || "Découvrir mes économies"}</>}
                                                    </Button>
                                                </>
                                            }
                                        </Stack>
                                    </Box>
                                </motion.div>
                            )
                        }
                        if (activeSection === FORMULAIRE && !loadCustom) {
                            return (
                                <Slide in={activeSection === FORMULAIRE} {...({ timeout: 1000 })} direction='left'>
                                    <Box>
                                        <Stack>
                                            <h3 className="text-center m-0">
                                                {
                                                    dataHomepage["titre2"] ? (
                                                        <span style={{ color: dataHomepage["secondary_color"] || colors.dark2 }}>
                                                            {dataHomepage["titre2"]}
                                                        </span>
                                                    ) : (
                                                        <span>
                                                            <span style={{ color: dataHomepage["secondary_color"] || colors.pink }}>
                                                                Réalisez des économies </span>sur votre assurance de prêt
                                                        </span>
                                                    )
                                                }
                                            </h3>
                                            <p className="text-center">
                                                {
                                                    dataHomepage["paragraphe_2"] ? (
                                                        <span className='text-bold text-center'>{dataHomepage["paragraphe_2"]}</span>
                                                    ) : (
                                                        <span className='text-bold text-center'>
                                                            Indiquez vos coordonnées pour découvrir vos économies et afin que nous puissions vous contacter.
                                                        </span>
                                                    )
                                                }
                                            </p>
                                            {

                                                <Stack width={"100%"} mt={3}>
                                                    <Stack bgcolor={"white"} borderRadius={2.5} p={4} pt={0}>
                                                        {((): any => {
                                                            if (loading) {
                                                                return (<Loading color={dataHomepage["primary_color"] || colors.blue1} />)
                                                            }
                                                            if (!loading) {
                                                                return (
                                                                    <>
                                                                        {
                                                                            showError && (
                                                                                <Stack alignItems={"center"}>
                                                                                    <Stack bgcolor={"#f8d7da"} py={2.5} px={2} borderRadius={1} my={2} borderColor={"#f5c6cb"}>
                                                                                        <small className='m-0 century align-center' style={{ color: "#7c2932", fontSize: 15.5, display: "flex", alignItems: "center" }}>
                                                                                            <IoWarning size={22} /> Une erreur s'est produite lors de la création de votre demande.
                                                                                        </small>
                                                                                    </Stack>
                                                                                </Stack>
                                                                            )
                                                                        }
                                                                        <ThemeProvider theme={formTheme}>
                                                                            <CssBaseline />
                                                                            <Stack rowGap={2} px={{ md: 30, xs: 0 }} alignItems={"center"}>
                                                                                <Stack flexWrap={'wrap'} direction={'row'} columnGap={"35px"} rowGap={"18px"} alignItems={"end"}>
                                                                                    <RadioGroup
                                                                                        sx={{ columnGap: 3, rowGap: 1.5 }}
                                                                                        row
                                                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                                                        name="row-radio-buttons-group"
                                                                                        onChange={(event: any) => handleDataAdherents("gender", event.target.value)}
                                                                                    >
                                                                                        <FormControlLabel className={"radio-gender"} value="Homme" checked={(dataAdherents["gender"] === "Homme")} control={<Radio />} label="Homme" labelPlacement="start" />
                                                                                        <FormControlLabel className={"radio-gender"} value="Femme" checked={(dataAdherents["gender"] === "Femme")} control={<Radio />} label="Femme" labelPlacement="start" />
                                                                                    </RadioGroup>
                                                                                </Stack>
                                                                                <Stack direction={'row'} gap={"10px"} width={"100%"} alignItems={"end"}>
                                                                                    <FormControl fullWidth required>
                                                                                        <Label label={"Prénom"} color={!isBranding ? colors.pink : dataHomepage["secondary_color"]} />
                                                                                        <TextField onChange={(event: any) => handleDataAdherents("prenom", event.target.value)} variant="outlined" value={dataAdherents["prenom"]} />
                                                                                    </FormControl>
                                                                                    <FormControl fullWidth required>
                                                                                        <Label label={"Nom"} color={!isBranding ? colors.pink : dataHomepage["secondary_color"]} />
                                                                                        <TextField onChange={(event: any) => handleDataAdherents("nom", event.target.value)} variant="outlined" value={dataAdherents["nom"]} />
                                                                                    </FormControl>
                                                                                </Stack>
                                                                                <Stack direction={'row'} gap={"10px"} width={"100%"} alignItems={"end"}>
                                                                                    <FormControl fullWidth required>
                                                                                        <Label label={"Votre téléphone"} color={!isBranding ? colors.pink : dataHomepage["secondary_color"]} />
                                                                                        <TextField onChange={(event: any) => handleDataAdherents("telephone", event.target.value)} variant="outlined" value={dataAdherents["telephone"]} />
                                                                                    </FormControl>
                                                                                    <FormControl fullWidth required>
                                                                                        <Label label={"Votre adresse email"} color={!isBranding ? colors.pink : dataHomepage["secondary_color"]} />
                                                                                        <TextField onChange={(event: any) => handleDataAdherents("email", event.target.value)} variant="outlined" value={dataAdherents["email"]} />
                                                                                    </FormControl>
                                                                                </Stack>
                                                                                <FormControlLabel control={
                                                                                    <Checkbox
                                                                                        checked={dataAdherents["accept_data"]}
                                                                                        onChange={() => handleDataAdherents("accept_data", !dataAdherents["accept_data"])}
                                                                                    />
                                                                                }
                                                                                    label={
                                                                                        !isBranding ? (
                                                                                            <small className='checkbox-label'>
                                                                                                J'accepte que les données collectées ci-dessus soient utilisées pour la création de mon devis et accepte qu'on me recontacte dans le cadre de cette démarche.
                                                                                            </small>
                                                                                        ) : (
                                                                                            <small className='checkbox-label'>
                                                                                                J'accepte que les données collectées ci-dessus soient utilisées pour la création de mon devis d’assurance de prêt et accepte qu'on me recontacte par téléphone dans le cadre de cette démarche.
                                                                                                <br /> Pour plus d'information, &nbsp;<a style={{ color: dataHomepage["primary_color"] }} href={dataHomepage["privacy_url"]} target='_blank' rel="noreferrer"><strong>cliquez-ici</strong></a>.
                                                                                            </small>
                                                                                        )
                                                                                    }
                                                                                />
                                                                                <Stack mt={5} alignItems={"center"}>
                                                                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                                                                        <Button
                                                                                            style={{ fontFamily: "verdana" }}
                                                                                            sx={{ color: isBranding ? dataHomepage["secondary_color"] : colors.pink }}
                                                                                            className={`${!dataHomepage ? `btn-precedent` : `btn-precedent-branding`}`}
                                                                                            onClick={() => { setActiveSection(DEVIS) }} variant="text">
                                                                                            Modifier mes informations
                                                                                        </Button>
                                                                                        <Box sx={{ flex: '1 1 auto' }} />
                                                                                        <Button
                                                                                            sx={{
                                                                                                backgroundColor: dataHomepage["primary_color"] || colors.blue1,
                                                                                                '&:hover': {
                                                                                                    backgroundColor: dataHomepage["primary_color"] || colors.blue2,
                                                                                                }
                                                                                            }}
                                                                                            style={{ fontFamily: "verdana" }}
                                                                                            className={isAllDataValid() ? `${!isBranding ? `btn-suivant` : `btn-suivant-branding`}` : "disabled-btn"}
                                                                                            onClick={() => { addRdv() }} variant="contained" disabled={!isAllDataValid()}
                                                                                        >
                                                                                            {dataHomepage["cta2"] || "Valider mes coordonnées"}
                                                                                        </Button>
                                                                                    </Box>
                                                                                </Stack>
                                                                            </Stack>
                                                                        </ThemeProvider>
                                                                    </>
                                                                )
                                                            }
                                                        })()}
                                                    </Stack>
                                                </Stack>
                                            }
                                        </Stack>
                                    </Box>
                                </Slide>
                            )
                        }
                        if (activeSection === ECONOMIE && !loadCustom) {
                            return (
                                <motion.div
                                    variants={slideVariants}
                                    initial="hidden"
                                    animate="visible"
                                    exit="exit"
                                    transition={{ duration: 1 }}
                                >
                                    <Box>
                                        <Stack width={"100%"} mt={3}>
                                            <Stack px={{ xs: 0, md: 20 }}>
                                                <SuccessMessage notShowAction={true} showOrHideDialog={showOrHideDialog} prenom={dataAdherents.prenom} dataHomepage={isBranding ? dataHomepage : null} />
                                            </Stack>
                                            <Stack px={{ md: 20, xs: 0 }}>
                                                <Stack className={!isEditing ? "box-border" : "box-border-disabled"} bgcolor={"#fff"} alignItems={"center"} justifyContent={"center"} gap={2} mt={4} px={5} py={4} borderRadius={3}>
                                                    <Grid container>
                                                        <Grid container item md={5} xs={12}>
                                                            <Stack width={"100%"} pr={{ xs: 0, md: 3 }} alignItems={{ md: "end", xs: "start" }} justifyContent={{ md: "end", xs: "start" }}>
                                                                <p className='m-0'>Economisez jusqu'à</p>
                                                                <h2
                                                                    style={{ color: isBranding ? dataHomepage["secondary_color"] : colors.pink }}
                                                                    className={`m-0`}
                                                                >
                                                                    {formatNumberWithCommas(economies?.total)} <sup>€</sup>
                                                                </h2>
                                                                <small className='m-0'>Soit {economies?.moyenne} € par mois en moyenne</small>
                                                            </Stack>
                                                        </Grid>
                                                        <Grid container item md={7} xs={12}>
                                                            <Stack width={"100%"} direction={"row"} alignItems={"center"} justifyContent={"start"} gap={3}>
                                                                <GiReceiveMoney color={!isBranding ? colors.blue2 : dataHomepage["primary_color"]} size={80} />
                                                                <Stack>
                                                                    <h1
                                                                        style={{ color: !isBranding ? colors.blue2 : dataHomepage["primary_color"] }}
                                                                        className={`m-0`}
                                                                    >
                                                                        -75%
                                                                    </h1>
                                                                    <small>par rapport à l'assurance de votre banque</small>
                                                                </Stack>
                                                            </Stack>
                                                        </Grid>
                                                    </Grid>
                                                </Stack>
                                            </Stack>
                                            <Stack mt={5} alignItems={"center"}>
                                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                                    <Button
                                                        sx={{ color: isBranding ? dataHomepage["secondary_color"] : colors.pink }}
                                                        className={`${!dataHomepage ? `btn-precedent` : `btn-precedent-branding`}`}
                                                        onClick={() => { (!queryParams.get('partenaire') && !isBranding) ? navigate("/emprunteur") : setActiveSection(DEVIS) }} variant="text">
                                                        Refaire un devis
                                                    </Button>
                                                </Box>
                                            </Stack>
                                        </Stack>
                                    </Box>
                                </motion.div>
                            )
                        }
                    })()}
                </Stack>
                {(!queryParams.get('partenaire') && !isBranding) && (
                    <>
                        <Stack width={"100%"} pt={{ md: 10, xs: 8 }}>
                            <h2 style={{ color: isCustom ? "#fff" : "unset" }}>Pourquoi choisir Julia <span className='bg-doodle-pink'> Assurance</span> ?</h2>
                            <Grid container py={{ md: 6, xs: 2 }} rowSpacing={{ md: 6, xs: 2 }} columnSpacing={4} justifyContent={"center"}>
                                <Grid item md={4} xs={12} >
                                    <Stack className='card-engagement' borderRadius={"15px"} p={4}>
                                        <Box component="img" pb={4} src={oneIcon} alt="un icon" width={{ md: "70px", xs: "36px" }} />
                                        <h3 style={{ margin: 0, paddingBottom: 6 }}>Expertise reconnue en assurance </h3>
                                        <p>Bénéficiez de notre savoir-faire approfondi pour des solutions d'assurance sur-mesure.</p>
                                    </Stack>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <Stack className='card-engagement' borderRadius={"15px"} p={4}>
                                        <Box component="img" pb={4} src={twoIcon} alt="deux icon" width={{ md: "70px", xs: "36px" }} />
                                        <h3 style={{ margin: 0, paddingBottom: 6 }}>Service client de qualité</h3>
                                        <p>Nos conseillers chaleureux et à l’écoute sont à votre disposition</p>
                                    </Stack>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <Stack className='card-engagement' borderRadius={"15px"} p={4}>
                                        <Box component="img" pb={4} src={threeIcon} alt="trois icon" width={{ md: "70px", xs: "36px" }} />
                                        <h3 style={{ margin: 0, paddingBottom: 6 }}>Gamme étendue de produits d’assurance</h3>
                                        <p>Trouvez l'assurance idéale parmi un large choix adapté à chaque besoin spécifique.</p>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Stack>
                        <Stack width={"100%"}>
                            <h2 style={{ color: isCustom ? "#fff" : "unset" }}>Nos partenaires assureurs de <span className='bg-doodle-green'>confiance</span> </h2>
                            <Stack direction={"row"} py={{ md: 8, xs: 4 }} alignItems={"center"} justifyContent={"center"} spacing={{ md: 12, xs: 4 }} flexWrap={"wrap"} >
                                <Box component="img" src={ugipLogo} alt="ugip" width={{ md: 120, xs: 80 }} />
                                <Box component="img" src={aprilLogo} alt="april" width={{ md: 120, xs: 80 }} />
                                <Box component="img" src={neolianeLogo} alt="neoliane" width={{ md: 120, xs: 80 }} />
                                <Box component="img" src={apviaLogo} alt="apivia" width={{ md: 120, xs: 80 }} />
                                <Box component="img" src={generaliLogo} alt="generali" width={{ md: 120, xs: 80 }} />
                                <Box component="img" src={micLogo} alt="mic" width={{ md: 120, xs: 80 }} />
                            </Stack>
                        </Stack>
                    </>
                )}
            </CustomContainer >
            {(!queryParams.get('partenaire') && !isBranding) && (<SatisfactionClients />)}
        </Container >
    );
};

export default ParcoursEmprunteurSimple;