import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Join from "../pages/Join/Join";
import About from "../pages/About/About";
import Home from "../pages/Home/Home";
import Emprunteur from "../pages/Emprunteur/Emprunteur";
import Sante from "../pages/Sante/Sante";
import ParcoursSante from "../pages/ParcoursSante/ParcoursSante";
import ParcoursSanteSimple from "../pages/ParcoursSanteSimple/ParcoursSanteSimple";
import ParcoursEmprunteur from "../pages/ParcoursEmprunteur/ParcoursEmprunteur";
import ParcoursEmprunteurSimple from "../pages/ParcoursEmprunteurSimple/ParcoursEmprunteurSimple";
import Cookies from "../components/Cookies/Cookies";
import Panier from "../components/Panier/Panier";
import MentionsLegales from "../pages/MentionsLegales/MentionsLegales";
import ProtectionsDonnees from "../pages/ProtectionsDonnees/ProtectionsDonnees";
import Signature from "../pages/Signature/Signature";
import Bienvenue from "../pages/Bienvenue/Bienvenue";
import Document from "../pages/Document/Document";
import DocumentUpload from "../pages/DocumentUpload/DocumentUpload";
import SimulateurEmprunteur from "../pages/SimulateurEmprunteur/SimulateurEmprunteur";
import { Helmet, HelmetProvider } from "react-helmet-async";


const App = () => {
    return (
        <HelmetProvider>
            <Router>
                <Header />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/sante" element={<Sante />} />
                    <Route path="/emprunteur" element={<Emprunteur />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/join" element={<Join />} />
                    <Route path="/devis-sante" element={<ParcoursSante />} />
                    <Route path="/devis-emprunteur" element={<ParcoursEmprunteur />} />
                    <Route path="/devis-emprunteur-simple" element={<ParcoursEmprunteurSimple />} />
                    <Route path="/devis-emprunteur-simple/:param" element={<ParcoursEmprunteurSimple />} />
                    <Route path="/simulateur" element={<SimulateurEmprunteur />} />
                    <Route path="/devis-sante-simple" element={<ParcoursSanteSimple />} />
                    <Route path="/devis-sante-simple/:param" element={<ParcoursSanteSimple />} />
                    <Route path="/signature" element={<Signature />} />
                    <Route path="/bienvenue" element={<Bienvenue />} />
                    <Route path="/mentions-legales"
                        element={
                            <>
                                <Helmet>
                                    <meta name="robots" content="noindex, nofollow" />
                                </Helmet>
                                <MentionsLegales />
                            </>
                        }
                    />
                    <Route path="/protection-des-donnees"
                        element={
                            <>
                                <Helmet>
                                    <meta name="robots" content="noindex, nofollow" />
                                </Helmet>
                                <ProtectionsDonnees />
                            </>
                        }
                    />
                    <Route path="/document" element={<Document />} />
                    <Route path="/document-upload" element={<DocumentUpload />} />
                    <Route path="*" element={<Home />} />
                </Routes>
                <Panier />
                <Footer />
                <Cookies />
            </Router>
        </HelmetProvider>
    );
};

export default App;