import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

interface InsuranceCostChartProps {
    loanAmount: number;
    loanDuration: number;
    currentRate: number;
    proposedRate: number;
    subscriptionYear: number;
}

export function InsuranceCostChart({
    loanAmount,
    loanDuration,
    currentRate,
    proposedRate,
    subscriptionYear
}: InsuranceCostChartProps) {
    // Générer les labels pour l'axe X (années)
    const labels = Array.from({ length: loanDuration }, (_, i) => {
        return `${subscriptionYear + i}`;
    });

    // Calculer les coûts annuels pour chaque année
    const calculateAnnualCosts = (rate: number) => {
        return Array.from({ length: loanDuration }, (_, year) => {
            const remainingAmount = loanAmount * (1 - year / loanDuration);
            return Math.round((remainingAmount * (rate / 100)));
        });
    };

    const bankCosts = calculateAnnualCosts(currentRate);
    const juliaCosts = calculateAnnualCosts(proposedRate);

    // Calculer les coûts cumulés
    const bankCumulativeCosts = bankCosts.reduce((acc, cost, i) => {
        const prevTotal = i > 0 ? acc[i - 1] : 0;
        return [...acc, prevTotal + cost];
    }, [] as number[]);

    const juliaCumulativeCosts = juliaCosts.reduce((acc, cost, i) => {
        const prevTotal = i > 0 ? acc[i - 1] : 0;
        return [...acc, prevTotal + cost];
    }, [] as number[]);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            mode: 'index' as const,
            intersect: false,
        },
        plugins: {
            legend: {
                position: 'top' as const,
                labels: {
                    usePointStyle: true,
                    padding: 20,
                    font: {
                        size: 12,
                        family: "'century', sans-serif"
                    }
                }
            },
            tooltip: {
                backgroundColor: 'rgba(255, 255, 255, 0.95)',
                titleColor: '#1e293b',
                bodyColor: '#1e293b',
                borderColor: '#e2e8f0',
                borderWidth: 1,
                padding: 12,
                callbacks: {
                    label: function (context: any) {
                        const value = context.raw;
                        return `${context.dataset.label}: ${value.toLocaleString('fr-FR')}€`;
                    }
                }
            }
        },
        scales: {
            x: {
                grid: {
                    display: false
                },
                title: {
                    display: true,
                    text: 'Années',
                    color: '#64748b',
                    font: {
                        size: 12,
                        family: "'century', sans-serif"
                    }
                }
            },
            y: {
                beginAtZero: true,
                grid: {
                    color: '#f1f5f9'
                },
                title: {
                    display: true,
                    text: 'Coût cumulé (€)',
                    color: '#64748b',
                    font: {
                        size: 12,
                        family: "'century', sans-serif"
                    }
                },
                ticks: {
                    callback: function (value: any) {
                        return `${value.toLocaleString('fr-FR')}€`;
                    },
                    font: {
                        family: "'century', sans-serif"
                    }
                }
            }
        }
    };

    const data = {
        labels,
        datasets: [
            {
                label: 'Coût cumulé avec votre banque',
                data: bankCumulativeCosts,
                borderColor: '#ef4444',
                backgroundColor: 'rgba(239, 68, 68, 0.1)',
                tension: 0.4,
                fill: true,
                pointRadius: 4,
                pointHoverRadius: 6
            },
            {
                label: 'Coût cumulé avec Julia',
                data: juliaCumulativeCosts,
                borderColor: '#22c55e',
                backgroundColor: 'rgba(34, 197, 94, 0.1)',
                tension: 0.4,
                fill: true,
                pointRadius: 4,
                pointHoverRadius: 6
            }
        ],
    };

    return (
        <div className="bg-white p-6 rounded-lg shadow-sm">
            <div style={{ height: '400px' }}>
                <Line options={options} data={data} />
            </div>
            <div className="mt-6 space-y-2">
                <p className="text-sm text-gray-500 text-center">
                    Zone verte : vos économies potentielles sur la durée du prêt
                </p>
                <p className="text-xs text-gray-400 text-center">
                    * Les montants sont calculés sur une base annuelle pour plus de lisibilité
                </p>
            </div>
        </div>
    );
}