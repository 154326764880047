import { createSearchParams, useNavigate } from "react-router-dom";
import { Button, Grid, Stack, Box, useTheme, useMediaQuery } from '@mui/material';
import { Container } from '@mui/system';
import familleImage from "../../assets/images/famille_santé@.png";
import hospitalisationIcon from "../../assets/icons/hospitalisation.svg";
import optiqueIcon from "../../assets/icons/optique.svg";
import dentaireIcon from "../../assets/icons/dentaire.svg";
import specialisteIcon from "../../assets/icons/spécialistes.svg";
import praticienIcon from "../../assets/icons/medecine douce.svg";
import ugipLogo from "../../assets/logos/UGIP_ASSURANCES_Logo_horizontal_RVB 1.svg";
import aprilLogo from "../../assets/logos/APRIL_QUADRI_BLEU_RVB[1] 1.svg";
import neolianeLogo from "../../assets/logos/Logo_Néoliane_RVB.png";
import generaliLogo from "../../assets/logos/1200px-Assicurazioni_Generali_(logo).svg.png";
import micLogo from "../../assets/logos/Logo-MIC_courrier.png";
import ContactForm from '../../components/ContactForm/ContactForm';
import Reussite from '../../components/Reussite/Reussite';
import { CustomContainer } from '../../components/CustomContainer/CustomContainer';
import ParcoursService from '../../services/services';
import { useDispatch } from 'react-redux';
import { changeCurrentErrorMessage, changeStep } from '../../redux/actions';
import colors from '../../colors/colors';
import './Sante.scss';


const stepList = {
    "criteres-sante": 0,
    "beneficiaires-sante": 1,
    "coordonees-sante": 2,
    "formule-sante": 3,
    "informations-sante": 4,
    "informations-paiement": 5,
    "signature": 6
};


function Sante() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    const product = "DevisSanteClient";
    let erreur = `
        Une erreur inconnue s'est produite, 
        veuillez attendre un peu puis réessayer ou contactez nous à contact@julia-assurance.fr !
    `;

    const apiCheckStepManager = async () => {
        if (localStorage.getItem('secu_id_sante')) {
            await ParcoursService.api_check_step_manager(product).then(async (data: any) => {
                if (data['new'] !== null) {
                    if (data['new']) {
                        apiCreateStepManager();
                    } else {
                        dispatch(changeStep(stepList[data["step"] as keyof object]));
                        navigate({ pathname: "/devis-sante", search: `?${createSearchParams({ come_back: 'true' })}` })
                    }
                }
            }).catch(() => {
                console.log("__error_check__");
                dispatch(changeCurrentErrorMessage(erreur));
            });
        } else {
            apiCreateStepManager();
        }
    };

    const apiCreateStepManager = async () => {
        ParcoursService.api_create_step_manager(product).then(async (data: any) => {
            localStorage.setItem('secu_id_sante', data['numero_devis']);
            localStorage.setItem('private_id_sante', data['private_id']);
            dispatch(changeStep(stepList[data["next_step"] as keyof object]));
            navigate("/devis-sante")
        }).catch(() => {
            console.log("__error_create__")
            dispatch(changeCurrentErrorMessage(erreur));
        });
    };


    return (
        <div style={{ overflowX: "hidden" }}>
            <CustomContainer>
                <Stack width={"100%"} pt={{ xs: 12, md: 20 }}>
                    <Stack direction={"column"} justifyContent={"left"} display={{ md: "none", xs: "flex" }}>
                        <h3 style={{ marginBottom: 10 }}>Nos solutions d'assurance santé</h3>
                        <hr style={{ color: colors.teal, width: 50, margin: 0, height: 4, backgroundColor: colors.teal }} />
                    </Stack>
                    <Grid container>
                        <Grid item md={6} order={{ xs: 2, md: 1 }}>
                            <Stack direction={"column"} justifyContent={"left"} display={{ md: "flex", xs: "none" }}>
                                <h3 style={{ marginBottom: 10 }}>Nos solutions d'assurance santé</h3>
                                <hr style={{ color: colors.teal, width: 50, margin: 0, height: 4, backgroundColor: colors.teal }} />
                            </Stack>
                            <h1>Votre assurance santé facile et <span className={"bg-pink"}>sur mesure</span> !</h1>
                            <p>Nous sommes engagés à vous trouver une solution complète et adaptée à vos besoins, permettant d'accéder aux meilleurs soin de santé</p>
                            <Button className="btn-recevoir-blue" sx={{ mt: 2 }} variant="contained" onClick={() => apiCheckStepManager()} fullWidth={matches ?? false}>
                                Découvrir nos offres?
                            </Button>
                        </Grid>
                        <Grid item md={6} order={{ xs: 1, md: 2 }}>
                            <Box component="img" src={familleImage} alt="famille" width={{ md: "unset", xs: "100%" }} />
                        </Grid>
                    </Grid>
                </Stack>
            </CustomContainer>
            <CustomContainer>
                <Stack width={"100%"} pt={10}>
                    <h2>Des offres <span className={"bg-doodle-circle"}>adaptées</span> à vos besoins de santé</h2>
                    <Grid container py={{ md: 6, xs: 2 }} rowSpacing={{ md: 6, xs: 2 }} columnSpacing={4} justifyContent={"center"}>
                        <Grid item md={4} xs={12}>
                            <Box component="img" pb={2.5} src={hospitalisationIcon} alt="hospitalistion icon" width={{ xs: "40px", md: "unset" }} />
                            <h3 style={{ margin: 0, paddingBottom: 5 }}>Hospitalisation</h3>
                            <p>
                                Les frais des séjours dans les hôpitaux <span className='p-bold'> remboursés à 100%.</span>
                                <br />Evoquez également avec votre conseiller si vous souhaitez une chambre individuelle.
                            </p>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Box component="img" pb={2.5} src={optiqueIcon} alt="optique icon" width={{ xs: "40px", md: "unset" }} />
                            <h3 style={{ margin: 0, paddingBottom: 5 }}>Optique</h3>
                            <p>
                                Les lunettes sont <span className='p-bold'> prises en charge de 270€ à 420€ </span>en fonction de la complexité des verres
                                <br />Les lentilles sont remboursées à hauteur de 350€ par an.
                            </p>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Box component="img" pb={2.5} src={dentaireIcon} alt="dentaire icon" width={{ xs: "40px", md: "unset" }} />
                            <h3 style={{ margin: 0, paddingBottom: 5 }}>Dentaire</h3>
                            <p>
                                Remboursement <span className='p-bold'> jusqu'à 400€ par an pour les implants </span> et <span className='p-bold'> 1200€ pour les prothèses dentaires. </span>
                                <br />Toutes nos offres incluent également une offre 100% santé.
                            </p>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Box component="img" pb={2.5} src={specialisteIcon} alt="specialiste icon" width={{ xs: "40px", md: "unset" }} />
                            <h3 style={{ margin: 0, paddingBottom: 5 }}>Spécialistes</h3>
                            <p>
                                Consultation <span className='p-bold'> entièrement remboursée pour les médecins conventionnés </span>en secteur 1, 2 ou OPTAM.
                                <br />Jusqu'à 180€ remboursés chez un cardiologue avec le bonus fidélité.
                            </p>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Box component="img" pb={2.5} src={praticienIcon} alt="praticien icon" width={{ xs: "40px", md: "unset" }} />
                            <h3 style={{ margin: 0, paddingBottom: 5 }}>Praticiens non remboursés</h3>
                            <p>
                                Nous couvrons <span className='p-bold'> jusqu'à 8 séances par an </span>chez les ostéopathes, chiropracteurs, diététiciens, pédicures, etc.
                            </p>
                        </Grid>
                    </Grid>
                </Stack>
            </CustomContainer>
            <Container className={`px-0 ${!matches ? "half-background-list" : "bg-blue-list"}`} maxWidth={false} sx={{ mt: 5 }}>
                <CustomContainer>
                    <Grid container bgcolor={{ md: "white", xs: colors.blue }}>
                        <Grid container item md={5} xs={12} className={"radius-right"} bgcolor={colors.blue} pr={{ md: 35, xs: 0 }} py={{ md: 7, xs: 0 }} alignItems={"center"}>
                            <Stack direction={"column"} spacing={2} alignItems={"baseline"}>
                                <h3 className='h3-white' style={{ lineHeight: 1.8 }}>Une expérience client facilitée grâce à nos conseillers</h3>
                                <Button className="btn-recevoir-pink" variant="contained" onClick={() => apiCheckStepManager()} sx={{ display: { md: "block", xs: "none" } }}>
                                    Recevoir mon devis
                                </Button>
                            </Stack>
                        </Grid>
                        <Grid item md={7} xs={12}>
                            <Stack bgcolor={"transparent"} height={"100%"} spacing={{ md: "unset", xs: 4 }} justifyContent={{ md: "space-between", xs: "unset" }} ml={{ md: -20, xs: 0 }} mt={{ md: 6, xs: 4 }}>
                                <Stack direction={"row"} spacing={{ md: 5, xs: 2 }} alignItems={"center"} bgcolor={colors.blue2} py={{ md: 4, xs: 1 }} pl={{ md: 10, xs: 2 }} borderRadius={{ md: "20px", xs: "10px" }}>
                                    <h1 className='h1-white' style={{ fontSize: matches ? 55 : 65 }}>1</h1>
                                    <p className='p-white' style={{ fontSize: matches ? 16 : 18 }}>Choisissez votre assurance santé sur mesure</p>
                                </Stack>
                                <Stack direction={"row"} spacing={{ md: 5, xs: 2 }} alignItems={"center"} bgcolor={colors.blue2} py={{ md: 4, xs: 1 }} pl={{ md: 10, xs: 2 }} borderRadius={{ md: "20px", xs: "10px" }}>
                                    <h1 className='h1-white' style={{ fontSize: matches ? 55 : 65 }}>2</h1>
                                    <p className='p-white' style={{ fontSize: matches ? 16 : 18 }}>Signez facilement votre contrat en ligne</p>
                                </Stack>
                                <Stack direction={"row"} spacing={{ md: 5, xs: 2 }} alignItems={"center"} bgcolor={colors.blue2} py={{ md: 4, xs: 1 }} pl={{ md: 10, xs: 2 }} borderRadius={{ md: "20px", xs: "10px" }}>
                                    <h1 className='h1-white' style={{ fontSize: matches ? 55 : 65 }}>3</h1>
                                    <p className='p-white' style={{ fontSize: matches ? 16 : 18 }}>Gérez vos remboursements</p>
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Button className="btn-recevoir-pink" variant="contained" onClick={() => apiCheckStepManager()} sx={{ display: { md: "none", xs: "block" }, mt: "60px" }} fullWidth={matches ?? false}>
                        Recevoir mon devis
                    </Button>
                </CustomContainer>
            </Container>
            <CustomContainer>
                <Stack width={"100%"} pt={{ md: 20, xs: 6 }} pb={4}>
                    <h2>Nos partenaires assureurs de <span className='bg-doodle-green'>confiance</span> </h2>
                    <Stack py={1}>
                        <p>
                            Chez Julia Assurance, nous sommes fiers de collaborer avec des assureurs de confiance, soigneusement selectionnés, afin de
                            <span className='p-bold'> vous offrir les meilleures garanties</span> en matière d'assurance santé.
                            <br /><br />
                            Nous partageons avec eux un engagement commun envers la satisfaction du client et la protection de votre bien-être.
                            Lorsque vous choisissez Julia Assurances, vous bénéficiez de <span className='p-bold'> l'expertise et de la réputation des meilleurs assureurs sur le marché. </span>
                            <span style={{ display: matches ? "none" : "inline" }}>Ensemble, nous veillons à ce que votre assurance santé soit solide, fiable et adaptée à vos exigences spécifiques.</span>
                        </p>
                    </Stack>
                    <Stack direction={"row"} py={{ md: 8, xs: 4 }} alignItems={"center"} justifyContent={"center"} spacing={{ md: 12, xs: 4 }} flexWrap={"wrap"} >
                        <Box component="img" src={ugipLogo} alt="ugip" width={{ md: 120, xs: 80 }} />
                        <Box component="img" src={aprilLogo} alt="april" width={{ md: 120, xs: 80 }} />
                        <Box component="img" src={neolianeLogo} alt="neoliane" width={{ md: 120, xs: 80 }} />
                        <Box component="img" src={generaliLogo} alt="generali" width={{ md: 120, xs: 80 }} />
                        <Box component="img" src={micLogo} alt="mic" width={{ md: 120, xs: 80 }} />
                    </Stack>
                </Stack>
            </CustomContainer>
            <Reussite />
            <ContactForm />
        </div>
    );
};


export default Sante;