/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Grid, Stack, Box, useMediaQuery, useTheme } from '@mui/material';
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { Container } from '@mui/system';
import familleImage from "../../assets/images/famille_lp@2x.png";
import teamImage from "../../assets/images/AC2A4165.jpg";
import oneIcon from "../../assets/icons/one.svg";
import twoIcon from "../../assets/icons/two.svg";
import threeIcon from "../../assets/icons/three.svg";
import arrowRight from "../../assets/icons/arrow-right.svg";
// import pencilIcon from "../../assets/icons/pencil.svg";
// import phoneIcon from "../../assets/icons/phone.svg";
import colors from '../../colors/colors';
import ContactForm from '../../components/ContactForm/ContactForm';
import { CustomContainer } from '../../components/CustomContainer/CustomContainer';
// import Actualite from '../../components/Actualite/Actualite';
import SatisfactionClients from '../../components/SatisfactionClients/SatisfactionClients';
import generaliLogo from "../../assets/logos/1200px-Assicurazioni_Generali_(logo).svg.png";
import micLogo from "../../assets/logos/Logo-MIC_courrier.png";
import aprilLogo from "../../assets/logos/APRIL_QUADRI_BLEU_RVB[1] 1.svg";
import apviaLogo from "../../assets/logos/apivia.png";
import allianzLogo from "../../assets/logos/Allianz.svg.png";
import swissLogo from "../../assets/logos/2560px-Logo_Swiss_Life.svg.png";
import axaLogo from "../../assets/logos/AXA_Logo.svg.png";
import preparLogo from "../../assets/logos/Prepar.svg";
import ParcoursService from '../../services/services';
import { changeCurrentErrorMessage, changeParcoursType, changeStep } from '../../redux/actions';
import { scroller } from 'react-scroll';
import './Home.scss';


const qualities = [
    {
        title: "Honnêteté",
        description: "Nous avons à cœur d’être transparent dans nos échanges avec nos clients. Nous proposons toujours des offres qui correspondent à vos attentes.",
        color: colors.pink
    },
    {
        title: "Satisfaction",
        description: "Nous offrons un accompagnement 100% humain. Nos conseillers experts étudient minutieusement tous les dossiers.",
        color: colors.green
    },
    {
        title: "Efficacité",
        description: "Trouver la solution idéale à nos clients est essentiel pour nous, et nous mettons tout en œuvre pour leur proposer le meilleur.",
        color: colors.teal
    }
];

const assurances = [
    {
        name: "Complémentaire santé",
        className: "expertise-card-sante expertise-card",
        path: "/sante",
        parcoursPath: "/devis-sante",
        product: "DevisSanteClient",
        description: "Découvrez nos complémentaires santé adaptées à votre besoin, de la plus protectrice à la plus économique.",
        stepList: {
            "criteres-sante": 0,
            "beneficiaires-sante": 1,
            "coordonees-sante": 2,
            "formule-sante": 3,
            "informations-sante": 4,
            "informations-paiement": 5,
            "signature": 6
        }
    },
    {
        name: "Assurance emprunteur",
        className: "expertise-card-emprunteur expertise-card",
        path: "/emprunteur",
        // parcoursPath: "/devis-emprunteur",
        parcoursPath: null,
        product: "DevisEmprunteurClient",
        description: `Propriétaires et futurs propriétaires: faîtes des économies sur votre assurance emprunteur ! Devis en ligne.`,
        stepList: {
            "informations-bien": 0,
            "informations-pret": 1,
            "informations-personnelles": 2,
            "offre-emprunteur": 3,
            "informations-complementaires": 4,
            "signature": 5
        }
    },
    {
        name: "Assurance de prevoyance",
        className: "expertise-card-prevoyance expertise-card",
        path: null,
        parcoursPath: null,
        product: null,
        description: "Vous souhaitez protéger vos proches en cas de décès, accident, dépendance ?",
        stepList: null
    }
];

const partenaires = [
    generaliLogo, micLogo, aprilLogo, apviaLogo, allianzLogo, swissLogo, axaLogo, preparLogo
];


function Home() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    const queryParams = new URLSearchParams(location.search);

    let erreur = `
        Une erreur inconnue s'est produite, 
        veuillez attendre un peu puis réessayer ou contactez nous à contact@julia-assurance.fr !
    `;


    useEffect(() => {
        const isFromJoin = location.state?.isFromJoin;
        if (isFromJoin) {
            scrollTo("contact");
            const newLocationState = { ...location.state, isFromJoin: false };
            window.history.replaceState(newLocationState, '', location.pathname);
        };
    }, []);

    useEffect(() => {
        if (queryParams.get('mid') && queryParams.get('did')) {
            localStorage.setItem('private_id_sante', queryParams.get('mid') as string);
            localStorage.setItem('secu_id_sante', queryParams.get('did') as string);
            if (queryParams.get('source')) {
                dispatch(changeParcoursType(queryParams.get('source') as string));
            }
            if (queryParams.get('step')) {
                navigate("/devis-sante", { state: { stepParams: queryParams.get('step') } });
            } else {
                navigate("/devis-sante", { state: { finalizeParams: true } });
            }
        }
        if (!localStorage.getItem('url_ad_sante')) {
            localStorage.setItem('url_ad_sante', window.location.href);
        }
    }, []);


    const apiCheckStepManager = async (assurance: any) => {
        if (localStorage.getItem('secu_id_sante')) {
            await ParcoursService.api_check_step_manager(assurance.product).then(async (data: any) => {
                if (data['new'] !== null) {
                    if (data['new']) {
                        apiCreateStepManager(assurance);
                    } else {
                        dispatch(changeStep(assurance.stepList[data["step"] as keyof object]));
                        navigate({ pathname: assurance.parcoursPath, search: `?${createSearchParams({ come_back: 'true' })}` });
                    }
                }
            }).catch(() => {
                console.log("__error_check__");
                dispatch(changeCurrentErrorMessage(erreur));
            });
        } else {
            apiCreateStepManager(assurance);
        }
    };

    const apiCreateStepManager = async (assurance: any) => {
        await ParcoursService.api_create_step_manager(assurance.product).then(async (data: any) => {
            localStorage.setItem(`secu_id_${assurance.path.split("/")[1]}`, data['numero_devis']);
            localStorage.setItem(`private_id_${assurance.path.split("/")[1]}`, data['private_id']);
            dispatch(changeStep(assurance.stepList[data["next_step"] as keyof object]));
            navigate(assurance.parcoursPath)
        }).catch(() => {
            console.log("__error_create__")
            dispatch(changeCurrentErrorMessage(erreur));
        });
    };

    const childContainerStyles = {
        display: 'flex',
        flexWrap: 'nowrap',
        overflowX: 'auto',
        py: 4,
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
    };

    const childStylesAssurances = {
        flex: '0 0 auto',
        maxWidth: '300px',
        minWidth: '300px',
    };

    const scrollTo = (sectionId: string) => {
        scroller.scrollTo(sectionId, {
            duration: 1000,
            offset: -150,
            smooth: true,
            spy: true
        });
    };


    return (
        <div style={{ overflowX: "hidden" }}>
            <CustomContainer>
                <Stack width={"100%"} pt={{ xs: 12, md: 20 }}>
                    <Grid container>
                        <Grid item md={6} order={{ xs: 2, md: 1 }}>
                            <h1 className={matches ? "m-0" : ""}>Chez Julia Assurance, le conseil qui fait la <span className={"bg-pink"}>différence</span> !</h1>
                            <p>Avec Julia Assurance, votre courtier 100% en ligne, bénéficiez d'un accompagnement complet pour trouver la meilleure offre d’assurance de prêt et d’assurance santé parmi celles de nos partenaires.</p>
                            <Stack direction={{ md: "row", xs: "column" }} flexWrap={"wrap"} gap={{ md: 6, xs: 2 }} mt={2}>
                                <Button className="btn-offre-blue" variant="contained" onClick={() => navigate("/emprunteur")}>
                                    Assurance de prêt
                                </Button>
                                <Button className="btn-offre-pink" variant="outlined" onClick={() => navigate("/sante")}>
                                    Complémentaire santé
                                </Button>
                            </Stack>
                        </Grid>
                        <Grid item md={6} order={{ xs: 1, md: 2 }}>
                            <Box component="img" src={familleImage} alt="famille" width={"100%"} />
                        </Grid>
                    </Grid>
                </Stack>
            </CustomContainer>
            <CustomContainer>
                <Stack width={"100%"} pt={{ md: 10, xs: 5 }}>
                    <Grid container spacing={{ md: 12, xs: 2 }} zIndex={2}>
                        <Grid container item md={6} order={{ xs: 2, md: 1 }}>
                            <Stack direction={"row"} alignItems={"center"} className='home-section' height={{ md: "486px", xs: "209px" }} width={'100%'} justifyContent={"end"}>
                                <Stack p={4} className='box-confiance' mr={{ md: -8, xs: 2 }} mt={{ md: "unset", xs: 28 }} width={{ md: "30%", xs: "40%" }}>
                                    <h3 className='text-magenta m-0'>+15000</h3>
                                    <p className='m-0'>clients nous font confiance.</p>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item md={6} order={{ xs: 1, md: 2 }}>
                            <h2 className='m-0'> Julia Assurance, votre <span className={"bg-highlight-1"}>courtier</span> en assurance !</h2>
                            <p style={{ marginTop: 50 }}>
                                Nous sommes spécialiste de la complémentaire santé et de la prévoyance.<br />
                                Nos experts vous aident à comparer les contrats et trouver les plus adaptées en terme de prix et de garanties.
                            </p>
                        </Grid>
                    </Grid>
                </Stack>
            </CustomContainer>
            <Container className={"bg-home px-0"} maxWidth={false} sx={{ pt: 28, pb: 10, mt: -5 }}>
                <CustomContainer>
                    <Stack width={"100%"} bgcolor={"white"} borderRadius={"20px"} py={5}>
                        <Grid container spacing={3} px={4}>
                            {
                                qualities.map((qua, index) => (
                                    <Grid item md={4} key={index}>
                                        <Stack direction={"row"} alignItems={"start"} spacing={3}>
                                            <Box component="img" src={arrowRight} alt="arrow" borderRadius={"8px"} bgcolor={qua.color} />
                                            <Stack direction={"column"} gap={2.5}>
                                                <h3 className='m-0' style={{ color: qua.color }}>{qua.title}</h3>
                                                <p className='m-0'>{qua.description}</p>
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Stack>
                </CustomContainer>
            </Container>
            <Container className={"px-0 half-background-home"} maxWidth={false} sx={{ py: { md: 10, xs: 5 } }}>
                <CustomContainer>
                    <h2 className='h2-white'>Découvrez nos offres d'<span className={"bg-highlight-3"}>assurance</span> </h2>
                    {
                        !matches ? (
                            <Grid container mt={8} spacing={2.5} minWidth={"100%"}>
                                {
                                    assurances.map((assurance, index) => (
                                        <Grid container item key={index} md={4}>
                                            <Stack direction={'column'} className={`${assurance.className} cursor-pointer`} height={"450px"} justifyContent={"end"} width={"100%"}>
                                                <Stack p={4} gap={4} alignItems={"start"}>
                                                    <h3 className='h3-white'>{assurance.name}</h3>
                                                    <p className='m-0 text-white century' style={{ fontSize: 17 }}>{assurance.description}</p>
                                                    <Button className="btn-recevoir-pink" variant="contained"
                                                        onClick={() => assurance.path === "/emprunteur" ? navigate("/devis-emprunteur-simple") : assurance.parcoursPath ? apiCheckStepManager(assurance) : scrollTo("contact")}
                                                    >
                                                        Obtenir un tarif
                                                    </Button>
                                                    <p onClick={() => assurance.path ? navigate(assurance.path) : {}} className='m-0 century' style={{ color: "white", textDecoration: "underline" }}>
                                                        En savoir plus
                                                    </p>
                                                </Stack>
                                            </Stack>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        ) : (
                            <Stack direction={"row"} spacing={3.5} mt={5} sx={childContainerStyles} className="hidden-scroll">
                                {
                                    assurances.map((assurance, index) => (
                                        <Stack direction={'column'} key={index} className={`${assurance.className} cursor-pointer`} height={"450px"} justifyContent={"end"} width={"100%"} style={{ ...childStylesAssurances }}>
                                            <Stack p={4} gap={4} alignItems={"start"}>
                                                <h3 className='h3-white'>{assurance.name}</h3>
                                                <p className='m-0' style={{ fontSize: 16, color: "#d0d0d0" }}>{assurance.description}</p>
                                                <Button className="btn-recevoir-pink" variant="contained" onClick={() => assurance.parcoursPath ? apiCheckStepManager(assurance) : {}}>
                                                    Obtenir un tarif
                                                </Button>
                                                <p onClick={() => assurance.path ? navigate(assurance.path) : {}} className='m-0 century' style={{ color: "white", textDecoration: "underline" }}>
                                                    En savoir plus
                                                </p>
                                            </Stack>
                                        </Stack>
                                    ))
                                }
                            </Stack>
                        )
                    }
                    {/* <p style={{ marginTop: 40, display: matches ? "none" : "block" }}>
                        Tous nos conseillers sont formés avec une réelle expertise dans leur domaine, ce qui nous permet de vous fournir les meilleurs conseils pour choisir votre contrat.
                        Nous connaissons parfaitement le marché de l’assurance et les offres de nos partenaires.
                        Ainsi, nous sommes en mesure de vous accompagner dans la recherche de votre assurance idéale.
                    </p> */}
                </CustomContainer>
            </Container>
            {/* <Container className={`px-0 ${!matches ? "bg-engagement" : ""}`} maxWidth={false} sx={{ py: 5 }}>
                <CustomContainer>
                    <h2>Nos engagements</h2>
                    {
                        !matches ? (
                            <Grid container spacing={10} px={2}>
                                <Grid item md={6}>
                                    <Stack direction={"column"} spacing={2} borderRadius={"20px"} p={4} gap={3} className='card-engagement'>
                                        <Box component="img" src={pencilIcon} alt="edit" borderRadius={"8px"} width={"70px"} />
                                        <h3 className='m-0' style={{ color: colors.pink }}>Une souscription facilitée</h3>
                                        <p className='m-0'>
                                            <span className='p-bold'>C’est 100% en ligne ! </span>
                                            Vous pouvez souscrire en ligne, transmettre un devis, consulter vos décomptes, obtenir votre carte de Tiers Payant...
                                        </p>
                                    </Stack>
                                </Grid>
                                <Grid item md={6}>
                                    <Stack direction={"column"} spacing={2} borderRadius={"20px"} p={4} gap={3} className='card-engagement'>
                                        <Box component="img" src={phoneIcon} alt="edit" borderRadius={"8px"} width={"70px"} />
                                        <h3 className='m-0' style={{ color: colors.teal }}>Un service client réactif</h3>
                                        <p className='m-0'>
                                            Nos conseillers sont <span className='p-bold'> disponibles du lundi au vendredi de 9h à 18h </span>
                                            pour répondre à toutes vos demandes. Vous pouvez les contacter par e-mail ou par téléphone.
                                        </p>
                                    </Stack>
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid container spacing={4} px={0}>
                                <Grid item md={6}>
                                    <Stack direction={"row"} alignItems={"start"} spacing={2}>
                                        <Box component="img" src={pencilIcon} alt="edit" borderRadius={"8px"} width={"35px"} />
                                        <Stack direction={"column"} gap={2.5}>
                                            <h3 className='m-0' style={{ color: colors.pink }}>Une souscription facilitée</h3>
                                            <p className='m-0'>
                                                <span className='p-bold'>C’est 100% en ligne ! </span>
                                                Vous pouvez souscrire en ligne, transmettre un devis, consulter vos décomptes, obtenir votre carte de Tiers Payant...
                                            </p>
                                        </Stack>
                                    </Stack>
                                </Grid>
                                <Grid item md={6}>
                                    <Stack direction={"row"} alignItems={"start"} spacing={2}>
                                        <Box component="img" src={phoneIcon} alt="edit" borderRadius={"8px"} width={"35px"} />
                                        <Stack direction={"column"} gap={2.5}>
                                            <h3 className='m-0' style={{ color: colors.teal }}>Un service client réactif</h3>
                                            <p className='m-0'>
                                                Nos conseillers sont <span className='p-bold'> disponibles du lundi au vendredi de 9h à 18h </span>
                                                pour répondre à toutes vos demandes. Vous pouvez les contacter par e-mail ou par téléphone.
                                            </p>
                                        </Stack>
                                    </Stack>
                                </Grid>
                            </Grid>
                        )
                    }
                </CustomContainer>
            </Container> */}
            <Container className={`px-0 ${!matches ? "bg-engagement" : ""}`} maxWidth={false} sx={{ py: 5 }}>
                <CustomContainer>
                    <Stack width={"100%"} pt={{ md: 10, xs: 8 }}>
                        <h2>Pourquoi choisir Julia <span className='bg-doodle-pink'> Assurance</span> ?</h2>
                        <Grid container py={{ md: 6, xs: 2 }} rowSpacing={{ md: 6, xs: 2 }} columnSpacing={4} justifyContent={"center"}>
                            <Grid item md={4} xs={12} >
                                <Stack className='card-engagement' borderRadius={"15px"} p={4}>
                                    <Box component="img" pb={4} src={oneIcon} alt="un icon" width={{ md: "70px", xs: "36px" }} />
                                    <h3 style={{ margin: 0, paddingBottom: 6 }}>Expertise reconnue en assurance </h3>
                                    <p>Bénéficiez de notre savoir-faire approfondi pour des solutions d'assurance sur-mesure.</p>
                                </Stack>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Stack className='card-engagement' borderRadius={"15px"} p={4}>
                                    <Box component="img" pb={4} src={twoIcon} alt="deux icon" width={{ md: "70px", xs: "36px" }} />
                                    <h3 style={{ margin: 0, paddingBottom: 6 }}>Service client de qualité</h3>
                                    <p>Nos conseillers chaleureux et à l’écoute sont à votre disposition</p>
                                </Stack>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Stack className='card-engagement' borderRadius={"15px"} p={4}>
                                    <Box component="img" pb={4} src={threeIcon} alt="trois icon" width={{ md: "70px", xs: "36px" }} />
                                    <h3 style={{ margin: 0, paddingBottom: 6 }}>Gamme étendue de produits d’assurance</h3>
                                    <p>Trouvez l'assurance idéale parmi un large choix adapté à chaque besoin spécifique.</p>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Stack>
                </CustomContainer>
            </Container>
            <CustomContainer>
                <Stack width={"100%"} pt={{ md: 10, xs: 3 }} mb={{ md: 16, xs: 5 }} pb={4} alignItems={"center"}>
                    <h2 className=''>Nos <span className='bg-doodle-green'>partenaires</span> </h2>
                    <Grid container py={{ md: 5, xs: 2 }} alignItems={"center"} justifyContent={"center"} width={{ md: "60%", xs: "100%" }} >
                        {
                            partenaires.map((pa, index) => (
                                <Grid container item md={3} xs={4} key={index} justifyContent={"center"}>
                                    <Box component="img" src={pa} alt="ugip" width={{ md: 100, xs: 80 }} />
                                </Grid>
                            ))
                        }
                    </Grid>
                </Stack>
            </CustomContainer>
            <SatisfactionClients />
            {/* <Actualite /> */}
            <Container className={"px-0"} maxWidth={false} sx={{ bgcolor: { xs: colors.blue, md: "transparent" }, py: { xs: "64px", md: 0 } }}>
                <CustomContainer>
                    <h2 className={matches ? "h2-white" : ""} >Nous rejoindre ! 🔥</h2>
                    <Stack width={"100%"} pt={5} pb={{ md: 20, xs: 0 }}>
                        <Grid container alignItems={"center"}>
                            <Grid item md={5} display={{ xs: "none", md: "unset" }}>
                                <Box component="img" src={teamImage} alt="team" borderRadius={"20px"} width={"100%"} />
                            </Grid>
                            <Grid item md={7}>
                                <Stack py={{ md: 10, xs: 0 }} px={{ md: 7, xs: 0 }} borderRadius={"20px"} className='paper-join' ml={{ md: -8, xs: 0 }} alignItems={{ md: "start", xs: "center" }} spacing={2} bgcolor={{ md: "white", xs: colors.blue }}>
                                    <p className={matches ? "p-white" : ""} style={{ fontSize: matches ? 16 : 18 }}>
                                        Tu as envie de rejoindre une jeune entreprise dynamique, de travailler au sein d'une équipe soudée et de développer tes compétences professionnelles ?
                                        <br /> <br />
                                        Ne cherche plus ! Rejoins-nous dès maintenant ! 🔥
                                    </p>
                                    <Button className="btn-recevoir-pink" variant="contained" onClick={() => navigate("/join")} fullWidth={matches ?? false}>
                                        Decouvrir nos offres d'emploi
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Stack>
                </CustomContainer>
            </Container>
            <ContactForm />
        </div>
    );
};


export default Home;