import { Stack } from '@mui/material';
import { ClipLoader } from 'react-spinners';
import colors from '../../colors/colors';
import './Loading.scss';


export default function Loading(props: any) {
    return (
        <Stack justifyContent={"center"} alignItems={"center"} mt={4}>
            <ClipLoader color={props.color ?? colors.blue1} size={props.size || 70} />
        </Stack>
    );
};