import { Container } from '@mui/system';
import { Box, Grid, Stack, useMediaQuery, useTheme } from '@mui/material';
import AntoinePhoto from "../../assets/images/Antoine.png";
import BricePhoto from "../../assets/images/Brice_V2.png";
import BenjaminPhoto from "../../assets/images/Benjamin.png";
import MaxPhoto from "../../assets/images/Max.png";
import ArrowRight from "../../assets/icons/arrow-right.svg";
import ContactForm from '../../components/ContactForm/ContactForm';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { CustomContainer } from '../../components/CustomContainer/CustomContainer';
import colors from '../../colors/colors';
import './About.scss';


const steps = [
    {
        label: '2020',
        description: 'Fondation de Julia Assurance',
        color: '#149DB1'
    },
    {
        label: 'Déc 2021',
        description: 'Julia Assurance compte 25 conseillers et plus de 10 000 clients ! ',
        color: '#BBD148'
    },
    {
        label: '2021',
        description: "Ouverture d'une antenne dans la région Rémoise",
        color: '#149DB1'
    },
    {
        label: 'Nov 2022',
        description: 'Julia Assurance se lance dans le courtage en assurance de prêt',
        color: '#BBD148'
    },
    {
        label: 'Juillet 2023',
        description: 'Julia Assurance rejoint le groupe Santiane',
        color: '#149DB1'
    }
];


function About() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <div style={{ overflowX: "hidden" }}>
            <div className='bg-about'>
                <CustomContainer>
                    <Stack width={"100%"} pt={{ xs: 15, md: 20 }} pb={{ md: 30, xs: 0 }}>
                        <h1 className='h1-white'>Julia Assurance, votre <span className="bg-pink">courtier</span> en assurance !</h1>
                        {
                            !matches ? (
                                <p className='p-white' style={{ fontSize: 18 }}>
                                    Fondée en 2020, Julia Assurance est une <span className='p-bold'>assurtech dynamique et innovante</span> spécialisée dans l'offre d'assurance santé et de prêt. En tant que courtier en assurance, nous sommes engagés à vous trouver votre contrat idéal en proposant des solutions adaptées à vos besoins.
                                    <br /> <br />
                                    Notre équipe de conseillers qualifiés est là pour vous accompagner et vous guider dans le choix des meilleures options d'assurance.
                                </p>
                            ) : (
                                <p className='p-white' style={{ fontSize: !matches ? "18px" : "16px", textAlign: "center" }}>
                                    Fondée en 2020, Julia Assurance est une <span className='p-bold'>assurtech dynamique et innovante</span> spécialisée dans l’offre d’assurance santé et de prêt. En tant que courtier en assurance, nous sommes engagés à vous trouver votre contrat idéal en proposant des solutions adaptées.
                                </p>
                            )
                        }
                    </Stack>
                </CustomContainer>
            </div>
            <CustomContainer>
                <Stack px={{ md: 18, xs: 0 }} mt={{ md: -20, xs: 4 }}>
                    <Grid container className='box-flottant' px={{ md: 12, xs: 0 }} py={{ md: 8, xs: 2 }}>
                        <Grid item md={6} xs={6} px={4}>
                            <Stack className='century-bold' fontSize={50} textAlign={'center'} color={colors.green} pb="16px">15000</Stack>
                            <Stack fontSize={18} textAlign={'center'} color={colors.dark}>Clients en France Métropolitaine et en Outre-Mer</Stack>
                        </Grid>
                        <Grid item md={6} xs={6} px={4}>
                            <Stack className='century-bold' fontSize={50} textAlign={'center'} color={colors.teal} pb="16px">30</Stack>
                            <Stack fontSize={18} textAlign={'center'} color={colors.dark}>Conseillers experts en assurance</Stack>
                        </Grid>
                    </Grid>
                </Stack>
            </CustomContainer>
            <Container sx={{ pt: 14, pb: 7 }}>
                <Grid container>
                    <Grid container item md={6} xs={12} spacing={matches ? 4 : 0} order={{ xs: 2, md: 1 }}>
                        <Grid item md={6} xs={6}>
                            <Box component="img" src={BenjaminPhoto} alt="Benjamin" width={{ xs: "100%", md: "unset" }} />
                        </Grid>
                        <Grid item md={6} xs={6}>
                            <Box component="img" src={BricePhoto} alt="Brice" width={{ xs: "100%", md: "unset" }} />
                        </Grid>
                        <Grid item md={6} xs={6}>
                            <Box component="img" src={AntoinePhoto} alt="Antoine" width={{ xs: "100%", md: "unset" }} />
                        </Grid>
                        <Grid item md={6} xs={6}>
                            <Box component="img" src={MaxPhoto} alt="Max" width={{ xs: "100%", md: "unset" }} />
                        </Grid>
                    </Grid>
                    <Grid item md={6} xs={12} order={{ xs: 1, md: 2 }}>
                        <Stack>
                            <p>« Julia Assurance est une start-up fondée par quatre amis passionnés : Brice, Benjamin, Antoine et Maximilien. Forts de plusieurs années d'expérience dans le domaine, nous avons décidé d'<strong>unir nos compétences et notre expertise pour créer Julia Assurance.</strong></p>
                            <p>Nous croyons fermement en l'<strong>importance de la confiance, de la proximité et de la personnalisation de la relation avec nos assurés.</strong> Notre objectif principal est de fournir un service client exceptionnel et des produits d'assurance répondant aux besoins spécifiques de chaque individu.</p>
                            <p>Ensemble, nous partageons une vision commune : comprendre les besoins de nos clients et les conseiller pour leur trouver des solutions adaptées parmi les offres de nos partenaires. »</p>
                            <p style={{ textAlign: 'end', fontFamily: 'brush-script', fontSize: '24px' }}>Benjamin et Brice</p>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
            <div className="histoire" >
                <CustomContainer>
                    <Grid container px={{ md: 2, xs: 0 }} paddingBottom='68px' paddingTop='78px'>
                        <Grid item md={6}>
                            <Stack>
                                <h2 style={{ color: '#fff' }}>Notre <span className='bg-doodle-circle-pink'>Histoire</span></h2>
                            </Stack>
                            <Timeline>
                                {steps.map((step, index) => (
                                    <TimelineItem key={index} style={{ padding: 0 }}>
                                        <TimelineOppositeContent sx={{ m: 'auto 0' }} fontWeight={'bold'} fontSize={32} color={step.color}>
                                            {step.label}
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                            <TimelineConnector sx={{ bgcolor: index === 0 ? "transparent" : "white" }} />
                                            <TimelineDot sx={{ backgroundColor: step.color, borderRadius: 0, margin: 0 }} />
                                            <TimelineConnector sx={{ bgcolor: index === 4 ? "transparent" : "white" }} />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <p className='p-white' style={{ fontSize: matches ? 16 : 18 }}>{step.description}</p>
                                        </TimelineContent>
                                    </TimelineItem>
                                ))}
                            </Timeline>
                        </Grid>
                        <Grid item md={6}>
                            <Stack className='box-white' gap={4}>
                                <Stack style={{ background: '#fff' }} alignItems={'start'} direction={'row'}>
                                    <Box component="img" sx={{ background: '#CC287B' }} borderRadius={1} width={'40px'} mr={4} src={ArrowRight} alt="april" />
                                    <Stack>
                                        <h3 className='text-magenta' style={{ margin: 0 }}>Honneteté</h3>
                                        <p>
                                            Nous avons à cœur d'être transparents dans nos échanges avec nos clients. Nous proposons toujours des offres qui correspondent à vos attentes.
                                        </p>
                                    </Stack>
                                </Stack>
                                <Stack style={{ background: '#fff' }} alignItems={'start'} direction={'row'}>
                                    <Box component="img" sx={{ background: '#BBD148' }} borderRadius={1} width={'40px'} mr={4} src={ArrowRight} alt="april" />
                                    <Stack>
                                        <h3 className='text-verty' style={{ margin: 0 }}>Satisfaction</h3>
                                        <p>
                                            Nous offrons un accompagnement 100% humain. Nos conseillers experts étudient minutieusement tous les dossiers.
                                        </p>
                                    </Stack>
                                </Stack>
                                <Stack style={{ background: '#fff' }} alignItems={'start'} direction={'row'}>
                                    <Box component="img" sx={{ background: '#149DB1' }} borderRadius={1} width={'40px'} mr={4} src={ArrowRight} alt="april" />
                                    <Stack>
                                        <h3 className='text-turquoise' style={{ margin: 0 }}>Efficacité</h3>
                                        <p>
                                            Trouver la solution idéale à nos clients est essentiel pour nous, et nous mettons tout en œuvre pour leur proposer le meilleur.
                                        </p>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                </CustomContainer>
            </div>
            <ContactForm />
        </div>
    );
}

export default About;